/*-----------------
    32. Chat
-----------------------*/

.chat-page {
	.footer {
		display: $__none;
	}
	.content {
		padding: 0;
		.container-fluid {
			padding: 0;
		}
	}
}
.chat-window {
    position: $__relative;
    @extend %display-flex;
	@include respond-below(custom991) {
		overflow: hidden;
	}
    .chat-cont-left {
        background: $__white;
        border-radius: 10px;
	    -ms-flex: 0 0 35%;
	    flex: 0 0 35%;
	    max-width: 35%;
	    z-index: 4;
		padding: 15px;
	    @include position($__relative, null, null, null, 0);
	    @include respond-below(custom1199) {
	    	-ms-flex: 0 0 40%;
		    flex: 0 0 40%;
		    max-width: 40%;
	    }
	    @include respond-below(custom991) {
	    	-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		    max-width: 100%;
		    transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
		    width: $__full__width;
		    border-right: 0;
	    }
        .chat-scroll {
            min-height: 300px;
			max-height: 440px;
        }
	    a {
	    	&.media {
	    		.media-body {
	    			& > div {
			    		&:first-child {
			    			.user-name, .user-last-chat {
			    				@include respond-below(custom479) {
			    					max-width: 160px;
			    				}
			    			}
			    		}
			    	}
	    		}
	    	}
	    }
	    .chat-header {
		    background-color: $__white;
		    border-bottom: 1px solid $__greycolor;
		    color: $__greycolor;
		    height: 72px;
			justify-content:space-between;
			align-items: $__center;
			display: $__flex;
		    @include margin-padding(null, 0 15px);
			span {
			    font-size: $__font__size__20;
			    font-weight: $__medium;
			    text-transform: $__capitalize;
			}
			.chat-compose {
			    color: $__greycolor;
				display: $__inline__flex;
			}
		}		
		.chat-search {
			@include margin-padding(null, 0 0 20px 0);
			width: $__full__width;
			.form-custom {
				width: $__full__width;
			    .form-control {
				    background-color: $__light__whitesmoke;
				    padding-right: 36px;
					border-radius: 5px !important;
                    height: 48px;
				    &:focus {
					    border-color: $__greycolor;
					    box-shadow: $__none;
					}
					&::placeholder {
	                    font-size: $__font__size__14;
                        color: $__darkgray;
	                }
				}
				.input-group-prepend {
					align-items: $__center;
					display: $__flex;
				    color: $__text_dark;
				    pointer-events: $__none;
				    z-index: 4;
				    @include position($__absolute, 0, 15px, 0, null);
				}
			}
		}
		.chat-users-list {
			background-color: $__white;
            @include margin-padding(null, null);
			h3 {
				font-weight: 600;
				@include margin-padding(0 0 20px 0,null);
			}
			a {
				&.media {
				    background: $__white;
                    box-shadow: 0px 4px 24px rgba(227, 227, 227, 0.25);
                    border-radius: 3px;
				    @include margin-padding(0 0 15px 0, 10px);
				    @include transition(all 0.2s ease 0s);
                    @extend %display-flex;
				    &:last-child {
						border-bottom: 0;
					}
					.media-img-wrap {
					    margin-right: 8px;
					    position: $__relative;
					    .avatar {
						    height: 40px;
						    width: 40px;
							position: relative;
							img {
								border-radius: 10px;
							}
						}
						.status {
							height: 10px;
							width: 10px;
							border: 2px solid $__white;
							@include position($__absolute, null, 4px, 7px, null);
						}
					}
					.media-body {
						justify-content: space-between;
						display: $__flex;
                        flex: 1;
						& > div {
							&:first-child {
								.user-name {
									max-width: 250px;
								    overflow: $__hidden;
								    text-overflow: $__ellipsis;
								    white-space: $__nowrap;
								    color: $__secondarycolor;
    								text-transform: $__capitalize;
                                    font-size: $__font__size__14;
                                    font-weight: $__semibold;
    								@include respond-below(custom1399) {
								    	max-width: 180px;
								    }
    								@include respond-below(custom1199) {
								    	max-width: 150px;
								    }
    								@include respond-below(custom767) {
								    	max-width: 250px;
								    }
								    @include respond-below(custom479) {
								    	max-width: 160px;
								    }
								}
								.user-last-chat {
									max-width: 220px;
								    overflow: $__hidden;
								    text-overflow: $__ellipsis;
								    white-space: $__nowrap;
								    color: $__darkgray;
								    font-size: $__font__size__14;
								    line-height: $__font__size__24;
									@extend %display-flex-center;
									i {
										color: $__secondarycolor;
										margin-right: 5px;
									}
								    @include respond-below(custom1199) {
								    	max-width: 150px;
								    }
								    @include respond-below(custom767) {
								    	max-width: 250px;
								    }
								    @include respond-below(custom479) {
								    	max-width: 160px;
								    }
								}
							}
							&:last-child {
							    text-align: $__right;
							    .last-chat-time {
								    color: $__darkgray;
								    font-size: $__font__size__13;
								}
                                .badge {
                                    width: 20px;
                                    height: 20px;
                                    background-color: $__star__blue;
                                    border-radius: 50%;
                                    color: $__white;
                                    font-size: $__font__size__12;
                                    font-weight: $__bold;
                                    @include margin-padding(0 0 0 $__auto, 0);
                                    @extend %flex-align-center;
									min-width: 0;
                                }
							}
						}
					}
					&:hover {
						background-color: $__greycolor;
					}
					&.read-chat{
						.media-body {
							& > div {
								.user-last-chat {
									i {
										color: $__success;
									}
								}
								&:last-child {
									.last-chat-time {
										color: $__darkgray;
                                        font-size: $__font__size__13;
									}
                                    .badge {
                                        width: 20px;
                                        height: 20px;
                                        background-color: $__star__blue;
                                        border-radius: 50%;
                                        color: $__white;
                                        font-size: $__font__size__14;
                                        font-weight: $__bold;
                                    }
								}
							}
						}
					}
					&.active {
						background-color: $__dull__whitesmoke;
                        border: 2px solid $__primarycolor;
					}
				}
			}
		}
	}
	.chat-scroll {
		height: 400px;
	    max-height: 450px;
	    overflow-y: $__auto;
	}
	.chat-cont-right {
	    -ms-flex: 0 0 64%;
	    flex: 0 0 64%;
	    max-width: 64%;
        border-radius: 10px;
		background: $__white;
		box-shadow: 0px 4px 44px rgba(211, 211, 211, 0.25);
		@include margin-padding(0 0 0px 20px, null);
	    @include respond-below(custom1199) {
	    	-ms-flex: 0 0 60%;
		    flex: 0 0 60%;
		    max-width: 60%;
	    }
	    @include respond-below(custom991) {
			opacity: 0;
			visibility: $__hidden;
			@include position($__absolute, 0, -100%, null, null);
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
			width: $__full__width;
			margin-left: 0;
	    }
	    .chat-header {
		    border-bottom: 1px solid $__greycolor;
		    height: 72px;
			@extend %flex-align-center;
			justify-content: space-between;
		    @include margin-padding(null, 0 15px);
		    @include respond-below(custom991) {
		    	justify-content: start;
				-webkit-justify-content: start;
				-ms-flex-pack: start;
		    }
		    .back-user-list {
			    display: $__none;
			    margin-right: 5px;
			    margin-left: -7px;
			    @include respond-below(custom991) {
			    	display: $__block;
			    }
			}
			.media {
				@extend %display-flex-center;
				.media-img-wrap {
					position: $__relative;
					@extend %flex-align-center;
					margin-right: 12px;
					.avatar {
						height: 40px;
						width: 40px;
					}
					.status {
					    border: 2px solid $__white;
					    height: 10px;
					    width: 10px;
					    @include position($__absolute, null, 3px, 0, null);
					}
				}
				.media-body {
					.user-name {
					    font-weight: $__medium;
					    text-transform: $__capitalize;
					}
					.user-status {
					    color: $__darkgray;
					    font-size: $__font__size__14;
					}
				}
			}
			.chat-options {
				display: $__flex;
				@include respond-below(custom991) {
					margin-left: $__auto;
				}
				& > a {
				    border-radius: 50%;
				    height: 30px;
				    color: $__secondarycolor;;
				    margin-left: 10px;
				    width: 30px;
					align-items: $__center;
					display: $__flex;
					justify-content: $__center;
					&:hover {
						background: $__light__whitesmoke;
					}
				}
			}
		}
		.chat-body {
		    background-color: $__white;
		    ul {
		    	&.list-unstyled {
				    width: $__full__width;
				    @include margin-padding(0 $__auto, 15px);
				}
		    }
		    .media {
                @extend %display-flex;
		    	.avatar {
				    height: 40px;
				    width: 40px;
					flex-shrink: 0;
				}
				.media-body {
					margin-left: 20px;
					.msg-box {
						& > div {
							display: $__inline__block;
							position: $__relative;
							border-radius: .25rem;
							@include margin-padding(null, 10px 15px);
							p {
                                font-size: $__font__size__14;
                                font-weight: $__medium;
							    margin-bottom: 0;
							}
							.chat-msg-info {
								li {
									width: $__full__width;
								}
								.chat-time {                                
									font-size: $__font__size__14;
									font-weight: $__medium;
									@extend %display-flex;
									@extend %justify-content-between;
								}
							}
						}
						& + .msg-box {
							margin-top: 5px;
						}
					}
				}
				&.received {
					margin-bottom: 20px;
					.media-body {
						position: $__relative;
						.msg-box {
							& > div {
								background-color: $__dull__whitesmoke;
								.chat-time {
								    color: $__darkgray;
									.msg-seen {
										color: $__success;
									}
								}
							}
							&:first-child {
								&:before {
								    border-bottom: 6px solid $__transparent;
								    border-right: 6px solid $__dull__whitesmoke;
								    border-top: 6px solid $__transparent;
								    content: "";
								    height: 0;
								    width: 0;
								    @include position($__absolute, 8px, $__auto, null, -6px);
								}
							}
						}
					}
				}
				&.sent {
					.avatar { 
						margin-left: 15px;
					}
					.media-body {
						.msg-box {
						    padding-left: 50px;
						    position: $__relative;
						    &:first-child:before {
								border-bottom: 6px solid $__transparent;
								border-left: 6px solid $__primarycolor;
								border-top: 6px solid $__transparent;
							    content: "";
							    height: 0;
							    width: 0;
							    @include position($__absolute, 8px, -4px, null, $__auto);
							}
							& > div {
								.chat-time {
								    color: $__white;
									.msg-seen {
										color: $__success;
									}
								}
							}
						}
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
				&.sent {
				    margin-bottom: 20px;
				    .media-body {
						align-items: flex-end;
						display: $__flex;
						flex-direction: column;
						justify-content: flex-end;
					    margin-left: 0;
                        width: $__full__width;
					    .msg-box {
					    	& > div {
					    		background-color: $__primarycolor;
					    		p {
								    color: $__white;
								}
								.chat-msg-info {
								    flex-direction: row-reverse;
								    li {
									    padding-left: 0;
									    padding-right: 0;
									    position: $__relative;
									}
								}
								.chat-msg-attachments {
									flex-direction: row-reverse
								}
								&:hover {
									.chat-msg-actions {
										opacity: 1;
									}
								}
					    	}
					    }
					}
				}
				&.read-chat {
					.chat-msg-info {
						.chat-time {
							.msg-seen {
								color: $__success;
							}
						}
					}
				}
		    }
		    .chat-date {
			    font-size: $__font__size__12;
                font-weight: $__medium;
				color: $__darkblue;
                @include margin-padding(20px 0, null);
			    overflow: $__hidden;
			    position: $__relative;
			    text-align: $__center;
			    text-transform: $__capitalize;
			    &:before {
				    background-color: $__greycolor;
				    content: "";
				    height: 1px;
				    margin-right: 28px;
				    width: $__full__width;
				    @include position($__absolute, 50%, 50%, null, null);
				}
				&:after {
				    background-color:$__greycolor;
				    content: "";
				    height: 1px;
				    margin-left: 28px;
				    width: $__full__width;
				    @include position($__absolute, 50%, null, null, 50%);
				}
			}
		}
		.chat-footer {
		    position: $__relative;
		    @include margin-padding(null, 10px 15px);
			@include respond-below(custom767) {
				@include margin-padding(null, 5px);
			}
		    .input-group {
			    width: $__full__width;
			    .form-control {
				    background-color: $__dull__whitesmoke;
				    border: $__none;
				    border-radius: 5px !important;
				    &:focus {
					    background-color: $__transparent;
					    border: $__none;
					    box-shadow: $__none;
					}
					&::placeholder {
	                    font-size: $__font__size__14;
	                }
				}
				.input-group-append {
                    @include position($__absolute, 50%, 10px, null, null);  
                    @include transform(translateY(-50%));
					.btn {
						background-color: $__transparent;
					    border: $__none;
					    color: $__greycolor;
					    &.msg-send-btn {
						    background-color: $__primarycolor;
						    border-color: $__primarycolor;
						    color: $__white;
						    width: 42px;
                            height: 46px;
						    font-size: $__font__size__20;
						    border-radius: 5px;
						}
					}
				}
				.input-group-prepend {     
                    line-height: 60px;             
					.btn {
						background-color: $__transparent;
					    border: $__none;
					    color: $__greycolor;
					}
				}
			}
			.form-custom {
				@extend %display-flex-center;
				.input-group-prepend {
					margin-right: 15px;
				}
				.send-blk {
					position: relative;
					width: 100%;
					.form-control {
						height: 52px;
						background: $__light__whitesmoke;
						border: 0;
						&:focus {
							box-shadow: none;
						}
					}
					.input-group-append {
						position: absolute;
						right: 5px;
						top: 5px;
						.btn {
							padding: 0;
							width: 48px;
							height: 42px;
							background: linear-gradient(93.86deg, #006177 -2.6%, #269089 67.39%, #7ABC82 110.84%);
							border-radius: 5px;
							border-right: none;
							i {
								color: $__white;
							}
						}
					}
				}
			}
		}
	}
	.chat-msg-info {
	    clear: $__both;
	    list-style: $__none;
	    @include margin-padding(10px 0 0, 0);
	    align-items: $__center;
	    display: $__flex;
	    flex-wrap: wrap;
	    li {
		    font-size: $__font__size__14;
		    padding-right: $__font__size__16;
		    position: $__relative;
		    &:not(:last-child) {
		    	&:after {
					content: '';
					height: 4px;
					width: 4px;
					background: $__greycolor;
					border-radius: 50%;
					@include transform(translate(50%, -50%));
					@include position($__absolute, 50%, 8px, null, null);
				}
		    }
		    a {
		    	color: $__greycolor;
		    	&:hover {
					color: $__greycolor;
				}
		    }
		}
	}
	&.chat-slide {
		.chat-cont-left {
			@include respond-below(custom991) {
				left: -104%;
			}
		}
		.chat-cont-right {
		    @include respond-below(custom991) {
			    right: 0;
			    opacity: 1;
			    visibility: $__visible;		    	
		    }
		}
	}

}
.chat-seen {
	i {
		color: $__greycolor;
		font-size: $__font__size__16;
	}
}
.chat-msg-attachments {
	display: $__flex;
	width: $__full__width;
	@include margin-padding(0 -1px, 4px 0);
	& > div {
		@include margin-padding(0 1px, null);
	}
}
.chat-attachment {
	position: $__relative;
	max-width: 130px;
	overflow: $__hidden;
	border-radius: .25rem;
	img {
	    max-width: $__full__width;
	}
	&:before {
		background: $__greycolor;
		content: "";
		opacity: 0.4;
		@include transition(all .4s);
		@include position($__absolute, 0, 0, 0, 0);
	}
	&:hover {
		&:before {
			opacity: 0.6;
		}
		.chat-attach-caption {
			opacity: 0;
		}
		.chat-attach-download {
			opacity: 1;
		}
	}
}
.chat-attach-caption {
	color: $__white;
	padding: 7px 15px;
	font-size: $__font__size__14;
	opacity: 1;
	@include transition(all .4s);
	@include position($__absolute, null, 0, 0, 0);
}
.chat-attach-download {
	opacity: 0;
	color: $__white;
	width: 32px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.2);
	text-align: $__center;
	transform: translate(-50%, -50%);
	@include transition(all .4s);
	@include position($__absolute, 50%, null, null, 50%);
	&:hover {
		color: $__greycolor;
		background: $__white;
	}
}
.chat-attachment-list {
	display: $__flex;
	margin: -5px;
	li {
		width: 33.33%;
		padding: 5px;
	}
}
.chat-attachment-item {
	border: 5px solid rgba(230, 239, 251, 0.5);
	height: $__full__width;
	min-height: 60px;
	text-align: $__center;
	font-size: $__font__size__30;
	@extend %flex-align-center;
}
.chat-msg-actions {
	opacity: 0;
	z-index: 2;
	@include transform(translateY(-50%));
	@include transition(all .4s);
	@include position($__absolute, 50%, null, null, -30px);
	& > a {
	    color: $__greycolor;
	    font-size: $__font__size__24;
	    @include margin-padding(null, 0 10px);
	    &:hover {
			color: $__greycolor;
		}
	}
}
@keyframes blink {
	50% {
		opacity: 1
	}
}
.btn-file {
    font-size: $__font__size__20;
    overflow: $__hidden;
    position: $__relative;
    vertical-align: $__middle;
	align-items: $__center;
	display: $__inline__flex;
	justify-content: $__center;
	@include margin-padding(null, 0 0.75rem);
	input {
    cursor: pointer;
	    filter: alpha(opacity=0);
	    font-size: $__font__size__20;
	    height: $__full__width;
	    margin: 0;
	    opacity: 0;
	    width: $__full__width;
	    @include position($__absolute, 0, 0, null, null);
	}
}
.incoming-btns {
	margin-top: 20px;
}
.call-wrapper {
    position: $__relative;
    height: calc(100vh - 150px);
    @include respond-below(custom991) {
    	height: calc(100vh - 140px);
    }
    @include respond-below(custom767) {
    	height: calc(100vh - 115px);
    }
}
.call-page {
	.footer {
		display: $__none;
	}
}
.dropdown-action {
	.dropdown-toggle {
		&::after {
			display: $__none;
		}
	}
}
.call-modal {
	.modal-body {
		@include margin-padding(null, 40px);
	}
	.modal-content {
	    border: 0;
	    border-radius: 10px;
	}
}
/*-----------------
	Incoming call
-----------------------*/
.call-box {
	.call-wrapper { 
		height: $__auto;
		text-align: $__center;
		.call-user { 
			margin-bottom: 30px;
			h4 {
				font-size: $__font__size__24;
			}
			span {
				display: $__block;
				font-weight: $__medium;
				text-align: $__center;
			}
		}
		.call-avatar {
			margin-bottom: 30px;
			cursor: $__pointer;
			animation: ripple 2s infinite;
		}
		.call-items {
			@extend %flex-align-center;
			.call-item {
			    background-color: rgba(255, 255, 255, 0.2);
			    border: 1px solid $__transparent;
			    border-radius: $__full__width;
			    color: $__white;
			    line-height: 0;
			    @include margin-padding(0 5px, 15px);
			    &:hover {
					opacity: 0.9;
				}
				&:first-child, &:last-child {
					margin-top: -30px;
				}
				&.call-end {
					background: $__greycolor;
					border: 1px solid $__greycolor;
					color: $__white;
					line-height: 0;
					border-radius: 100%;
					@include margin-padding(30px 20px 0, 20px);
				}
				&.call-start {
					background: $__greycolor;
					border: 1px solid $__greycolor;
					color: $__white;
					line-height: 0;
					border-radius: 100%;
					@include margin-padding(30px 20px 0, 20px);
				}
			}
		}
	}
	&.incoming-box {
		.call-wrapper {
			.call-items {
				.call-item {
					&.call-start, &.call-end {
						@include margin-padding(0 10px, null);
					}
				}
			}
		}
	}
	.call-avatar {
	    height: 140px;
	    max-width: 140px;
	    min-width: 140px;
	    position: $__relative;
	    width: $__full__width;
	    border: 10px solid $__greycolor;
	    border-radius: 100%;
	}
	.btn {
	    background: rgba(0, 0, 0, 0);
	    @include transition(all 0.3s ease 0s);
	}
}
@-webkit-keyframes ripple {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}
	100% {
		-webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
	}
}
@keyframes ripple {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}
	100% {
		-moz-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
		box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
	}
}
.chat-cont-right {
	.chat-body {
		.media {
			&.sent {
				.media-body {
					.msg-box {
						.chat-msg-info {
							li:not(:last-child) {
								&::after {
								    right: $__auto;
								    left: 8px;
								    @include transform(translate(-50%, -50%));
								    background: $__greycolor;
								}
							}
						}
					}
				}
			}
		}
	}
}
.green-active {
	width: 8px;
	height: 8px;
	border-radius: 50px;
	background: $__chat__green;
	position: absolute;
    bottom: 0;
    right: 6px;
}