// @import '../../assets/variables.scss';
// @import '../../assets/global.scss';
.wrapperMain {
    padding: 20px 32px;
}
.banner {
        padding: 0 32px;
    img {
        object-fit: cover;
    }
}
.profileBlock {
    background: #FFFDE8;
    padding: 32px 24px;
    gap: 24px;
    border-radius: 8px;
    margin-bottom: 16px;
    margin-top: -100px;
    position: relative;
    z-index: 1;
    .memberImage {
        img {
            width: 120px;
            height: 120px;
            min-width: 120px;
            object-fit: cover;
            border-radius: 100px;
        }
    }
    .memberInfo {
        .name_private {
           gap: 32px;
           h3 {
                font-family: "Cabin";
                font-size: 24px;
                font-weight: 400;
                line-height: 29.16px;
                text-align: left;
                color: #1A1A1A;
            }
            button.dropdown-toggle {
                background: transparent;
                border: none;
                color: #1A1A1A;
                font-family: "Cabin";
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                text-align: left;
                &:hover, &:active, &:focus {
                    background: transparent;
                    border: none;
                    color: #1A1A1A;
                }
            }
            .dropdown-menu {
                min-width: fit-content;
                padding: 5px 15px;
            }
        }
        p {
            font-family: "Cabin";
            font-size: 16px;
            font-weight: 400;
            line-height: 19.44px;
            text-align: left;
            margin: 12px 0 32px 0;
            color: #1A1A1A;
        }
        ul.memberDetail {
            list-style: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            gap: 12px;
            li {
                background: #FCF6B3;
                padding: 8px;
                border: 1px solid #FFE5003D;
                font-family: "Cabin";
                font-size: 14px;
                font-weight: 400;
                line-height: 17.01px;
                text-align: left;
                label {
                    font-weight: 500;
                    font-family: "Cabin";
                }
            }
        }
    }
    button.eidtProfileBtn {
        background: #1a80c7;
        color: #fff;
        padding: 7px 10px;
        border: 1px solid #1a80c714;
        border-radius: 8px;
        font-family: "Cabin";
        font-size: 14px;
        font-weight: 400;
        line-height: 17.01px;
        text-align: left;
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 10px;
    }
}
.threeBlocks {
    background: #FFFDE8;
    border: 1px solid #FCF6B3;
    border-radius: 8px;
    padding: 20px;
    .blockWrapper {
        background: #FCF6B3;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #FBF39F;
        gap: 8px;
        height: 100%;
        h4 {
            font-family: "Cabin";
            font-size: 24px;
            font-weight: 600;
            line-height: 29.16px;
            text-align: left;
            margin-bottom: 0px;
        }
        span {
            font-family: "Cabin";
            font-size: 14px;
            font-weight: 400;
            line-height: 17.01px;
            text-align: left;
        }
    }
}
hr.divider {
    border-top: 1px solid #F6F6F6;
    opacity: 1;
    margin: 40px 0;
}

.bg-yellow-light{
    background-color: #FFE500;
}


/* Media Query start */

@media only screen and (max-width: 991px) {
    .profileBlock {
        gap: 15px;
        padding: 20px 15px;
        .memberImage {
            img {
                width: 75px;
                height: 75px;
                min-width: 75px;
            }
        }
        .memberInfo {
            .name_private {
                gap: 10px;
            }
        }
    }
    .tabWrapper.nav.nav-pills {
        justify-content: space-between;
        gap: 5px;
        padding: 10px;
        .nav-item {
            width: calc(20% - 6px);
        }
    }
}

@media only screen and (max-width: 767px) {
    .threeBlocks {
        padding: 15px;
    }
    .profileBlock {
        flex-direction: column;
        margin-top: -40px;
        button.eidtProfileBtn {
            position: absolute;
            top: 40px;
            right: 15px;
        }
    }
    hr.divider {
        margin: 20px 0;
    }
}
@media only screen and (max-width: 480px) {
    .banner {
        img {
            min-height: 120px;
        }
    }
    .profileBlock {
        .memberInfo {
            .name_private {
                display: flex;
                flex-direction: column;
                button.dropdown-toggle {
                    padding-left: 0;
                }
            }
        }
    }
}