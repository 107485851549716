.sponsorenPage {
    h1 {
        color: #091524;
        text-transform: uppercase;
        font-weight: 100;
    }
    .imgFull  {
        margin-bottom: 30px;
    }
    .textFull {
        p {
            color: #000;
            font-weight: 300 !important;
        }
    }
    .contain1150 {
        max-width: 1150px;
        margin: auto;
        padding: 0 16px;
        ul.sponsorenList {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 16px;
            li {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 186px;
                border: 1px solid #333;
                border-radius: 20px;
                object-position: center;
                img {
                    
                    width: 100%;
                    height: auto;
                    object-position: center;
                    border: none;
                    border-radius: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .sponsorenPage {
        .contain1150 {
            ul.sponsorenList {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}
@media only screen and (max-width: 575px) {
    .sponsorenPage {
        .contain1150 {
            ul.sponsorenList {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}
@media only screen and (max-width: 479px) {
    .sponsorenPage {
        .contain1150 {
            ul.sponsorenList {
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
            }
        }
    }
}