/*-----------------
    22. About Us
-----------------------*/

.aboutus-info {
	margin-bottom: 60px;
	@include respond-below(custom991) {
		margin-bottom: 60px;
	}
	@include respond-below(custom767) {
		margin-bottom: 40px;
	}
	.banner {
		@include respond-below(custom767) {
			margin-bottom: $__value__15;
		}
	}
	.vision-mission {
		margin-top: 40px;
		h2 {
			margin-bottom: $__value__15;
		}
		.mission-bg {
			background: $__primarycolor;
			border-radius: 5px;
			padding: 24px;
			color: $__white;
			h2 {
				color: $__white;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
	p {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0px;
			@include respond-below(custom991) {
				margin-bottom: 10px;
			}
		}
	}
}
.ourteam {
	.info {
	    position: relative;
	    overflow: hidden;
	    cursor: pointer;
	    @include transition(all 1s ease-in-out 0s);
	    &:after {
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
			content: "";
			width: 100%;
			height: 100%;
			@include transition(all 0.9s ease-in-out 0s);
		}
	    &:hover {
	    	&:after {
	    		background: rgba(255, 255, 255, 0.95);
	    	}
			.short-info {
			    top: 0;
			}
			.more {
			    bottom: 0;
			}
	    }
	    .wrap {
		    position: relative;
		    h4, h6 {
		    	color: $__white;
		    }
			img {
				width: 100%;
			}
			.short-info {
				z-index: 1;
			    padding-top: 20px;
			    position: absolute;
			    top: 75%;
			    width: 100%;
			    @include transition(all 0.9s ease-in-out 0s);
			}
		}
		.more {
		    position: absolute;
		    bottom: -100%;
		    width: 100%;
		    @include transition(all 0.9s ease-in-out 0s);
		    z-index: 1;
		    @include respond-below(custom1023) {
				bottom: -135%;
			}
		    p {
		    	padding: 0 20px 20px 20px;
		    	margin-bottom: 0;
			    line-height: 20px;
			    color: $__secondarycolor;
			    @include respond-below(custom991) {
					line-height: 35px;
				}
				@include respond-below(custom575) {
					line-height: 1.7;
				}
			}
			.social-medias {
			    padding: 0 20px 10px 20px;
			    text-align: center;
			    li {
			    	margin-right: $__value__15;
			    	&.instagram {
			    		background: $__insta__gradient__bg;
			    	}
			    }
			}
		}
		&:hover {
			.short-info {
				h4 {
					color: $__secondarycolor;
				}
				h6{
					color: $__darkgray;
				}
			}
			.more {
				bottom: inherit;
				top: 50%;
				-webkit-transform: translate(0, -50%);
				-ms-transform: translate(0, -50%);
				transform: translate(0, -50%);
			}
			.wrap {
				.short-info {
					opacity: 0;
					transition: none;
				}
			}
		}
	}
	.section-heading {
		@include respond-below(custom991) {
			margin: 0 0 75px;
		}
	}
}
.aboutus-page {
	.content {
		padding-bottom: 0;
	}
}
.section {
	&.white-bg {
		padding: 80px 0px 56px;
		@include respond-below(custom991) {
			padding: 60px 0px 36px;
		}
		@include respond-below(custom767) {
			padding: 40px 0px 20px;
		}
	}
}