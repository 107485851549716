.passBlock {
    h4 {
        color: #202020;
        font-size: 24px;
    }
    a {
        color: #0062A7;
        text-decoration: underline;
        font-weight: 700;
    }
}