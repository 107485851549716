.contain1150 {
    max-width: 1150px;
    margin: auto;
    padding: 0 16px;
    ul.sponsorenList {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;
        li {
            img {
                border: 1px solid #333;
                border-radius: 20px;
                width: 100%;
                height: auto;
                object-position: center;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .contain1150 {
        ul.sponsorenList {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
@media only screen and (max-width: 575px) {
    .contain1150 {
        ul.sponsorenList {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media only screen and (max-width: 479px) {
    .contain1150 {
        ul.sponsorenList {
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }
    }
}