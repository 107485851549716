body:has(.homepage) header.header.header-trans,
body:has(.homepage) form.footer {
    display: none;
}
.contactBanner {
    padding: 50px 0;
}
.contactContent {
    h2 {
        color: #3D3D3D;
    }
    form {
        margin-top: 30px;
        textarea.form-control, input.form-control {
            border: 2px solid#3E3E3E !important;
            border-radius: 0px;
        }
        input.form-control {
            height: 48px;
        }
        button {
            background: #0062a7;
            border: 1px solid #0062a7;
            color: #fff;
            padding: 15px 30px;
            letter-spacing: 0.8px;
            border-radius: 0px;
        }
    }    
}
.sectionBgImage {
    background: url('../../../public/assets/img/bgLogo.jpg'), #fff;
    background-position: right center;
    background-repeat: no-repeat;
    padding: 150px 0;
    background-size: contain;
    .contentWrp {
        h2 {
            color: #3D3D3D;
            margin-bottom: 20px;
        }
    }
    button {
        background: #0062a7;
        border: 1px solid #0062a7;
        color: #fff;
        padding: 15px 30px;
        border-radius: 5px;
        letter-spacing: 0.8px;
    }
}

/* media Query start */
@media (min-width: 1199px) {
    .contentWrp {
        max-width: 350px;
    }
}

@media only screen and (max-width: 1700px) {
    .homeBanner {
        padding-bottom: 10%;
        .bannerContent {
            padding: 5% 0 0 0;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .homeBanner {
        .bannerContent {
            h1 {
                font-size: 70px;
            }
        }
    }
    .sectionBgImage {
        padding: 70px 0;
        background-size: cover;
        background-position: center center;
    }
}
@media only screen and (max-width: 991px) {
    .sectionBgImage {
        padding: 30px 0;
    }
}
@media only screen and (max-width: 767px) {
    .contactBanner {
        iframe {
            height: 400px;
            margin-top: 30px;
        }
    }
     
    // section.thankSection, section.followSection {
    //     h2 {
    //         font-size: 28px;
    //         line-height: normal;
    //     }
    //     .social {
    //         h2 {
    //             font-size: 28px;
    //             line-height: normal;
    //         }
    //     }
    // }
    // section.thankSection, .stadtSection {
    //     padding: 30px 0;
    // }
    // section.followSection {
    //     padding: 30px 0;
    //     img.Oskar_Mask {
    //         bottom: -64px;
    //         width: 100px;
    //     }
    // }
    // .stadtSection {
    //     h2 {
    //         margin-top: 25px;
    //         font-size: 28px;
    //     }
    // }
    // section.thankSection {
    //     .contain {
    //         text-align: center;
    //     }
    // }
    // section.followSection {
    //     .social {
    //         h2 {
    //             line-height: normal;
    //         }
    //     }
    // }
    // section.footerSection {
    //     .footer_Logo {
    //         margin-bottom: 30px;
    //         img {
    //             width: 80px;
    //         }
    //     }
    //     ul.imprintMenu {
    //         justify-content: center;
    //     }
    //    p {
    //         display: flex;
    //         justify-content: center;
    //     }
    // }
}

@media only screen and (max-width: 480px) {
    .homeBanner {
        .bannerContent {
            h1 {
                font-size: 30px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    .stadtSection {
        h2 {
            text-align: center;
        }
        p {
            font-size: 14px;
            margin-bottom: 10px;
            text-align: center;
        }
    }
    section.subscribeSection {
        padding: 30px 0;
        .appDownload {
            margin-bottom: 20px;
            text-align: center;
        }
        .btns {
            justify-content: center;
            a {
                img {
                    width: 120px;
                }
            }
        }
        .subscribeFormWrap {
            text-align: center;
            form {
                gap: 20px;
                input[type=email] {
                    height: 48px;
                }
                button {
                    padding: 10px;
                    width: 200px;
                }
            }
            p {
                padding-bottom: 10px;
            }
        }
    }
    section.footerSection {
        padding: 40px 0;
        .footer_menu {
            ul {
                grid-template-columns: 1fr 1fr;
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
        p {
            font-size: 14px;
        }
        ul.imprintMenu {
            li {
                a {
                    font-size: 14px;
                }
            }
        }
    }
}