/*-----------------
    28. Blog
-----------------------*/

.blog {
	.featured-venues-item {
		padding-bottom: 24px;
		.listing-item {
			h3 {
				margin-bottom: 15px;
				&.blog-title {
					margin-bottom: 24px;
				}
			}
			p {
				margin-bottom: 24px;
			}
			.listing-img {
				img {
					width: 100%;
				}
				&:hover { 
					img {
						-webkit-transform: scale(1.1) rotate(1deg);
			    		-ms-transform: scale(1.1) rotate(1deg);
					}
				}
			}
		}
	}
}
.similar-list {
	.listing-item {
		.listing-button {
			padding: 24px 0 0;
		}
	}
}
.blog-sidebar { 
	@include respond-below(custom767) {
		margin-top: 24px;
	}
	.card {
		&.tags-card {
			padding-bottom: 14px;
		}
		margin-bottom: 24px;
		box-shadow: $__row__box__shadow;
		h4 {
			border-bottom: 1px solid $__bright__gray;
			padding-bottom: 20px;
			margin-bottom: 24px;
		}
		.blog-search {
			position: $__relative;
			i {
				position: $__absolute;
			    top: 22px;
			    right: 15px;
			    color: $__darkash;
			}
		}
		.latest-posts {
			li {
				background: $__white;
				box-shadow: $__row__box__shadow;
				border-radius: 0px $__value__10 $__value__10 0px;
				padding: 15px;
				margin-bottom: 15px;
				display: inline-block;
				@include respond-below(custom767) {
					width: 100%;
				}
				&:last-child {
					margin-bottom: 0;
				}
				.post-thumb {
				    width: 80px;
				    height: 80px;
				    float: left;
				    overflow: hidden;
				    border-radius: 4px;
					@include respond-below(custom991) {
						margin-right: 10px;
						float: inherit;
						margin-bottom: 10px;
					}
				    a {
				    	img {
						    border-radius: 6px;
						    @include transform(translateZ(0));
							@include transition(all 2000ms cubic-bezier(.19,1,.22,1) 0ms);
						}
						&:hover {
							img { 
							    transform: scale(1.15);
							    -webkit-transform: scale(1.15);
							    -ms-transform: scale(1.15);
							}
						}
				    }
				}
				.post-info {
				    margin-left: 95px;
					@include respond-below(custom991) {
						margin-left: 0px;
					}
				    p {
				    	margin-bottom: 5px;
				    }
				    h6 {
				    	-webkit-line-clamp: 2;
					    -webkit-box-orient: vertical;
					    overflow: hidden;
					    text-overflow: ellipsis;
					    display: -webkit-box;
						margin-bottom: 0;
						a {
							color: #192335;
						}
					    &:hover {
					    	a { 
					    		color: $__primarycolor;
					    	}
					    }
				    }
				}
			}
		}
		.categories {
			li {
				margin-bottom: $__value__20;
				&:last-child {
					margin-bottom: 0;
					h6 {
						margin-bottom: 0;
					}
				}
				h6 {
					@include transition(all 0.5s ease-in-out 0s);
					a {
						@include transition(all 0.5s ease-in-out 0s);
						span {
							@include transition(all 0.5s ease-in-out 0s);
							color: $__darkash;
							font-size: $__font__size__14;
							font-weight: $__regular;
						}
					}
				}
				&:hover {
					h6 {
						a, span {
							color: $__primarycolor;
						}
					}
				}
			}
		}
		.tags {
			li {
				float: left;
				margin: 0 $__value__10  $__value__10 0;

				a {
					font-size: $__font__size__15;
					padding: 5px 15px;
					background: $__dull__whitesmoke;
					border-radius: 6px;
					display: inline-block;
					color: $__secondarycolor;
				}
				&:hover {
					a {
						background: $__primarycolor;
						color: $__white;
					}
				}
			}
		}
	}
}
.blog-pagination {
	margin-top: 36px;
	@include respond-below(custom767) {
		margin-top: 0;
	}
	.page-item {
		margin-right: 5px;
		.page-link {
			width: 32px;
			height: 32px;
			border-radius: 32px;
			border: 1px solid $__lightgray;
		    background: transparent;
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    font-size: $__font__size__14;
		    color: $__darkgray;
		    &.active, &:hover{
		    	background: $__primarycolor;
		    	border-color: $__primarycolor;
		    	color: $__white;
		    }
		    &:focus {
		    	box-shadow: none;
		    }
		}
	}
}
.blog-details {
	padding-bottom: 0;
	.listing-item {
		&.blog-info { 
			border-bottom: 1px solid $__lightgray;
			margin-bottom: 24px;
			.listing-content {
				background: none;
				box-shadow: none;
				padding: 24px 0 0 0;
				.listing-venue-owner {
					border-bottom: 1px solid $__lightgray;
					padding-bottom: 24px;
					margin-bottom: 24px;
					@include respond-below(custom991) {
						padding-bottom: 20px;
						margin-bottom: 20px;
					}
					&.blog-detail-owner {
						.navigation {
							@include respond-below(custom991) {
								margin-bottom: 10px;
								display: block;
							}
							a {
								@include respond-below(custom991) {
									margin-bottom: 10px;
								}
							}
							span {
								margin-right: 10px;
								@extend %display-flex-center;
								@include respond-below(custom991) {
									padding-left: 0;
									margin-left: 0;
								}
								&:last-child {
									margin-right: 0px;
								}
							}
						}
					}
					.navigation {
						font-weight: $__regular;
						img {
							border-color: $__white;
						}
						span {
							&:before {
								display: none;
							}
							i {
								font-size: $__font__size__16;
							}
						}
					}
					.btn {
						padding: 5px 13px;
						font-size: $__font__size__15;
						font-weight: $__regular;
						@include respond-below(custom991) {
							display: inline-block;
						}
						img {
							width: 12px;
							height: 12px;
							border-radius: 0;
							border: none;
						}
					}
				}
			}
		}
	}
	.gradient-bg {
		padding: 24px;
		background: $__head__gray;
		margin-bottom: 24px;
		@include respond-below(custom767) {
			flex-direction: column;
			padding: 20px;
		}
		p {
			padding-left: $__value__30;
			color: $__white;
			@include respond-below(custom767) {
				padding-left: 0;
			}
		}
		img {
			@include respond-below(custom767) {
				margin-bottom: 10px;
			}
		}
	}
	.blog-images {
		a {
			display: inline-block;
			margin: 0 20px 20px 0;
			overflow: hidden;
			@include respond-below(custom575) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			img {
				@include transition(all 0.5s ease-in-out 0s);
				-webkit-transform: scale(1.0);
				transform: scale(1.0);
				&:hover {
					-webkit-transform: scale(1.1);
    				transform: scale(1.1);
				}
			}
		}
	}
}
.text-list {
	padding: 0 0 24px 24px;
	li {
		list-style-type: disc;
	}
}
.tags-wrapper {
	h6 {
		margin-right: 10px;
		margin-bottom: 0;
	}
	.tags {
		li {
			float: left;
			margin: 5px $__value__10 5px 0;
			a {
				font-size: $__font__size__15;
				padding: 5px 15px;
				background: $__primarycolor;
				border-radius: 6px;
				display: inline-block;
				color: $__white;
			}
			&:hover {
				a {
					background: $__secondarycolor;
				}
			}
		}
	}
}
.social-medias-wrapper {
	margin-top: 5px;
	@include respond-below(custom991) {
		margin-top: 24px;
	}
	h6 {
		margin-right: 12px;
		margin-bottom: 0;
	}
	.social-medias {
		li {
			background: none;
			width: 24px;
			height: 24px;
			border-radius: $__value__20;
			i {
				color: $__darkgray;
				font-size: $__font__size__14;
			}
			&.facebook, &.linkedin {
				&:hover { 
						background: $__facebook;
					i { 
						color: $__white;
					}
				}
			}
			&.instagram, &.pinterest {
				&:hover { 
					background: $__instagram;
					i {
						color: $__white;
					}
				}
			}
			&.twitter {
				&:hover { 
					background: $__twitter;
					i {
						color: $__white;
					}
				}
			}
		}
	}
}
.blog-comments {
	margin-bottom: 24px;
	.dull-bg {
		background: $__whitesmoke;
		padding: 24px;
		border-radius: 6px;
		.author-group {
			.profile-pic {
				@include respond-below(custom767) {
					margin-right: 0;
					margin-bottom: 15px;
				}
			}
		}
		&.author-widget {
			border-radius: 4px;
			.profile-pic {
				a { 
					width: 100px;
					@include respond-below(custom991) {
						width: 70px;
					}
					img {
						width: 100px;
						height: 100px;
						border-radius: 100px;
						@include respond-below(custom991) {
							width: 70px;
							height: 70px;
						}
					}
				}
			}
		}
		.profile-pic {
			margin-right: 24px;
			min-width: 60px;
			max-width: 60px;
			img.rounded-circle {
				min-width: 60px;
				max-width: 60px;
				width: 60px;
				height: 60px;
				object-fit: cover;
			}
			a { 
				width: 70px;
				img {
					width: 70px;
					height: 70px;
					border-radius: 70px;
				}
			}
		}
		.info {
			.head {
				margin-bottom: 10px;
				h5 {
					margin-bottom: 0;
				}
				i {
					font-size: 5px;
					margin: 0 15px;
					display: inline-block;
				}
				span {
					color: $__darkgray;
				}
			}
			span, a {
				color: $__primarycolor;
				font-size: $__font__size__14;
			}
			a {
				&:hover {
					color: $__secondarycolor;
				}
			}
			p {
				margin-bottom: 0;
			}
		}
		ul {
			li {
				padding-bottom: 15px;
				margin-bottom: 15px;
				border-bottom: 1px solid $__bright__gray;
				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: none;
				}
			}
		}
	}
}
.new-comment{
	margin-bottom: 60px;
	box-shadow: $__row__box__shadow;
	border-radius: 6px;
	@include respond-below(custom767) {
		margin-bottom: 40px;
	}
	h4 {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid $__bright__gray;
	}
	button {
		border:none;
		margin-top: 8px;
	}
}
.section {
	&.similar-list {
		padding: 60px 0px 16px;
		@include respond-below(custom767) {
			padding: 40px 0px 0px;
		}
		h2 {
			@include respond-below(custom767) {
				margin-bottom: 35px;
			}
		}
	}
}
.content {
	&.carousal-blog {
		padding: 60px 0 36px;
		@include respond-below(custom991) {
			padding: 110px 0 36px;
		}
		@include respond-below(custom767) {
			padding: 90px 0 16px;
		}
	}
}
.blog-grid {
	.listing-venue-owner {
		.navigation {
			span {
				@include respond-below(custom767) {
					padding-top: 10px;
					display: inline-block;
				}
			}
		}
	}
}
.blog-slider {
	padding-top: 24px;
}
.similar-list {
	.listing-item {
		.listing-content {
			&.news-content {
				.listing-venue-owner {
					.navigation {
						@include respond-below(custom767) {
							display: block;
						}
						span::before {
							@include respond-below(custom767) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
.blog-grid {
	.listing-item .listing-button {
		padding: 24px 0 0;
	}
}

