.borderWrap.table {
    th,td {
        border: 1px solid #EAEDF0;
    }
    img.onwerImg {
        width: 75px;
        max-height: 75px;
        object-fit: cover;
    }
}
nav.headerHome.navbar::after {
    background: #0062A7;
    right: 0;
    width: 70%;
    height: 100px;
    border-radius: 100px 0 0 100px;
    content: '';
    position: absolute;
    z-index: 1;
}
body:has(.homepage) header.header.header-trans,
body:has(.homepage) form.footer {
    display: none;
}
.homeBanner {
    background-image: url('../../../public/assets/img/bannerImg.jpg');
    background-position: center center;
    background-size: cover;
    padding-bottom: 8%;
    .bannerContent {
        padding: 10% 0 0 0;

        h1 {
            color: #ffe500;
            // font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-size: 72pt;
            line-height: normal;
            font-family: 'FuturaPTHeavy';
        }
        p {
            font-size: 20px;
            color: #fff;
            font-weight: 100 !important;
            padding: 50px 0;
        }
        button.appBtn {
            background: #0062a7;
            border: 1px solid #0062a7;
            color: #fff;
            padding: 15px 30px;
            border-radius: 5px;
            font-style: italic;
            letter-spacing: 0.8px;
        }
    }
}
.headerHome {
    .menu.navbar-nav {
        padding: 0px 0 0 80px;
        position: relative;
        z-index: 2;
        ul.gap-48px {
            gap: 48px;
        }
        ul {
            li {
                a {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 300;
                }
                .dropdown {
                    a.dropdown-toggle  {
                        color: #fff;
                        padding: 0;
                    }
                    .dropdown-menu {
                        a {
                            padding: 5px 15px;
                            color: #000;
                            border-bottom: 0.51px solid #e5e5e5;
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                    .dropdown-menu.show {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 0px;
                    }
                }
            }
            li.teamSubmenu {
                .dropdown {
                    a.dropdown-toggle  {
                        color: #fff;
                        padding: 0;
                    }
                    .dropdown-menu {
                        a {
                            padding: 5px 15px;
                            color: #000;
                            white-space: nowrap;
                            border-bottom: 0.51px solid #e5e5e5;
                            border-left: 0.51px solid #e5e5e5;
                            &:last-child, &:nth-last-child(2) {
                                border-bottom: none;
                            }
                            &:nth-last-child(2) {
                                border-left: none;
                            }
                        }
                    }
                    .dropdown-menu.show {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        padding-bottom: 0px;
                    }
                }
            }
        }
    }
}
body:has(.homepage) {
    .menu.navbar-nav {
        ul {
            li {
                a {
                    color: #fff;
                } 
                .dropdown {
                    a.dropdown-toggle  {
                        color: #fff;
                    }
                }
            }
            li.teamSubmenu {
                .dropdown {
                    a.dropdown-toggle  {
                        color: #fff;
                    }
                }
            }
        }
    }    
}
.stadtSection {
    padding: 70px 0;
    background-color: #EBF3F8;
    h2, h2.ql-align-center {
        font-size: 48px !important;
        line-height: 61px;
        color: #091524;
        font-weight: 400 !important;
        margin-bottom: 20px;
        font-family: 'FuturaPTBookOblique';
        text-align: left;
        strong {
            font-weight: 400 !important; 
        }
    }
    p {
        font-size: 18px;
        color: #000;
        font-weight: 300;
        line-height: 28px;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
    }
}
section.thankSection {
    padding: 70px 0;
    background-color: #fff;
    .contain {
        max-width: 1000px;
        margin: auto;
        padding: 0 15px;
    }
    h2 {
        font-size: 48px;
        line-height: 61px;
        color: #091524;
        font-weight: 400;
        margin-bottom: 40px;
        font-family: 'FuturaPTBookOblique';
    }
}
section.followSection {
    background: #EBF3F8;
    padding: 70px 0;
    overflow: hidden;
    .social {
        max-width: 680px;
        margin: auto;
        text-align: center;
        padding: 0 15px;
        h2 {
            font-size: 48px;
            line-height: 61px;
            color: #091524;
            font-weight: 400;
            margin-bottom: 20px; 
            font-family: 'FuturaPTBookOblique';
        }
        ul {
            display: flex;
            justify-content: center;
            gap: 25px;
            li {
                a {

                }
            }
        }
    }
    img.Oskar_Mask {
        position: absolute;
        right: 0;
        bottom: -145px;
    }
}
section.subscribeSection {
    background: #FFE500;
    padding: 50px 0;
    label {
        color: #000;
        font-weight: 700;
        margin-bottom: 5px;
    }
    .btns {
        display: flex;
        gap: 22px;
    }
    .subscribeFormWrap {
        p {
            color: #000;
            font-weight: 100;
        }
        form {
            display: flex;
            gap: 28px;
        
            input[type="email"] {
                background-color: #e5cd00;
                border: 1px solid  #e5cd00;
                font-size: 13px;
                width: 100%;
                border-radius: 10px;
                padding: 15px;
                font-style: italic;
                letter-spacing: 0.8px;
                &:focus {
                    outline: none;
                    border: 1px solid #0062A7;
                }
            }
            button {
                background: #0062A7;
                border: 1px solid #0062A7;
                color: #fff;
                padding: 17px;
                width: 220px;
                border-radius: 10px;
            }
        }
    }
}
section.footerSection {
    background: #091524;
    padding: 120px 0;
    .footer_menu {
        h3 {
            color: #0062A7;
            font-weight: 100;
        }
        ul {
            display: grid;
            grid-template-columns: 180px 1fr;
            li {
                margin-bottom: 15px;
                a {
                    color: #fff;
                    font-weight: 300;
                    font-size: 18px;
                }
            }
        }
    }
    p {
        color: #fff;
        font-weight: 300;
        font-size: 18px;
    }
    ul.imprintMenu {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        li {
            a {
                color: #FFE500;
                font-size: 18px;
            }
        }
    }
}
.user-card-vorstand{
    min-width: 220px;
    background-color: #e0ecf7;
}
.user-card-vorstand.card-even{
    margin-top: 50px;
}
/* media Query start */
@media (min-width: 992px) {
    .headerHome {
        .align-items-start.container {
            align-items: center !important;
        }
    }
    .navbar-expand-lg .navbar-collapse {
        justify-content: flex-end;
    }
}
// @media (min-width: 992px) {
//     .navbar-expand-lg .navbar-collapse {
//         justify-content: flex-end;
//     }
// }
@media only screen and (max-width: 1700px) {
    nav.headerHome.navbar::after {
        width: 75%;
    }
    .homeBanner {
        padding-bottom: 10%;
        .bannerContent {
            padding: 5% 0 0 0;
        }
    }
}

@media only screen and (max-width: 1399px) {
    nav.headerHome.navbar::after {
        width: 81%;
    }
}
@media only screen and (max-width: 1350px) {
    nav.headerHome.navbar::after {
        width: 83%;
    }
}
@media only screen and (max-width: 1199px) {
    .headerHome {
        .menu.navbar-nav {
            ul.gap-48px {
                gap: 30px;
            }
        }
    }
    nav.headerHome.navbar::after {
        width: 80%;
    }
    .headerHome.navbar {
        a.navbar-brand {
            img {
                width: 100px !important;
                height: 100px !important;
                min-width: 100px;
            }
        }
    }
    .homeBanner {
        .bannerContent {
            h1 {
                font-size: 70px;
            }
        }
    }
}
@media only screen and (max-width: 1050px) {
    .headerHome {
        .menu.navbar-nav {
            ul.gap-48px {
                gap: 1rem;
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .headerHome {
        ul.gap-48px {
            gap: 20px !important;
        }
        .align-items-start.container {
            align-items: center !important;
        }
        a.navbar-brand {
            img {
                width: 80px;
            }
        }
        button.navbar-toggler {
            background: #ffe500;
            &:focus {
                box-shadow: none;
            }
        }
        .navbar-collapse {
            background: #fff;
            border-radius: 8px;
            position: absolute;
            top: 100px;
            left: 15px;
            right: 15px;
            
            .menu.navbar-nav {
                padding: 15px;
                ul {
                    flex-direction: column;
                    li {
                        .dropdown {
                            a.dropdown-toggle {
                                color: #000000 !important;
                            }
                        }
                        a {
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
    nav.headerHome.navbar::after {
        display: none;
    }
    .headerHome.navbar {
        a.navbar-brand {
            img {
                width: 80px !important;
                height: 80px !important;
                min-width: 80px;
            }
        }
    }
    .homeBanner {
        padding-bottom: 5%;
        .bannerContent {
            h1 {
                font-size: 50px;
            }
            p {
                padding: 10px 0;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    // .headerHome {
    //     ul.gap-48px {
    //         gap: 20px !important;
    //     }
    //     .align-items-start.container {
    //         align-items: center !important;
    //     }
    //     a.navbar-brand {
    //         img {
    //             width: 80px;
    //         }
    //     }
    //     button.navbar-toggler {
    //         background: #ffe500;
    //         &:focus {
    //             box-shadow: none;
    //         }
    //     }
    //     .navbar-collapse {
    //         background: #fff;
    //         border-radius: 8px;
    //         position: absolute;
    //         top: 100px;
    //         left: 15px;
    //         right: 15px;
            
    //         .menu.navbar-nav {
    //             padding: 15px;
    //             ul {
    //                 flex-direction: column;
    //                 li {
    //                     .dropdown {
    //                         a.dropdown-toggle {
    //                             color: #000000;
    //                         }
    //                     }
    //                     a {
    //                         color: #000000;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
     
    section.thankSection, section.followSection {
        h2 {
            font-size: 28px;
            line-height: normal;
        }
        .social {
            h2 {
                font-size: 28px;
                line-height: normal;
            }
        }
    }
    section.thankSection, .stadtSection {
        padding: 30px 0;
    }
    section.followSection {
        padding: 30px 0;
        img.Oskar_Mask {
            bottom: -64px;
            width: 100px;
        }
    }
    .stadtSection {
        h2 {
            margin-top: 25px;
            font-size: 28px;
        }
    }
    section.thankSection {
        .contain {
            text-align: center;
        }
    }
    section.followSection {
        .social {
            h2 {
                line-height: normal;
            }
        }
    }
    section.footerSection {
        .footer_Logo {
            margin-bottom: 30px;
            img {
                width: 80px;
            }
        }
        ul.imprintMenu {
            justify-content: center;
        }
       p {
            display: flex;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 480px) {
    .upperTab {
        .container {
            padding: 0;
        }
    }
    .homeBanner {
        .bannerContent {
            h1 {
                font-size: 30px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    .stadtSection {
        h2 {
            text-align: center;
        }
        p {
            font-size: 14px;
            margin-bottom: 10px;
            text-align: center;
        }
    }
    section.subscribeSection {
        padding: 30px 0;
        .appDownload {
            margin-bottom: 20px;
            text-align: center;
        }
        .btns {
            justify-content: center;
            a {
                img {
                    width: 120px;
                    height: auto !important;
                }
            }
        }
        .subscribeFormWrap {
            text-align: center;
            form {
                gap: 20px;
                input[type=email] {
                    height: 48px;
                }
                button {
                    padding: 10px;
                    width: 200px;
                }
            }
            p {
                padding-bottom: 10px;
            }
        }
    }
    section.footerSection {
        padding: 40px 0;
        .footer_menu {
            ul {
                grid-template-columns: 1fr 1fr;
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
        p {
            font-size: 14px;
        }
        ul.imprintMenu {
            li {
                a {
                    font-size: 14px;
                }
            }
        }
    }
}
