.notificationWrapper.card {
    max-width: 400px;
    width: 100%;
    padding: 0px;
    overflow-y: auto;
    max-height: 400px;
    scrollbar-width: thin;
    h5.card-header {
        padding: 15px;
    }
    .iconWrap {
        span {
            width: 40px;
            height: 40px;
            display: inline-block;
            background: #eee;
            border-radius: 50px;
            img {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                object-fit: cover;
            }
        }
    }
    small.text-muted {
        font-size: 12px;
    }
    p {
        font-size: 14px;
    }
}