/*-----------------
    19. Listing
-----------------------*/

.sortby-section {
	.sorting-info {
		background: $__white;
		border: 1px solid $__lightgray;
		box-shadow: $__row__box__shadow;
		border-radius: 10px;
		@include margin-padding(0 0 40px, 20px);
		@include respond-below(custom767) {
			padding: 20px;
		}
		.count-search {
			p {
				font-weight: $__medium;
				color: $__secondarycolor;
				margin-bottom: 0;
				@include respond-below(custom991) {
					text-align: center;
				}
				span {
					color: $__primarycolor;
				}
			}
		}
		.sortby-filter-group {
			@extend %display-flex-center;
			@extend %justify-content-end;
			flex-wrap: $__wrap;
			@include respond-below(custom991) {
				justify-content: $__center;
				@include margin-padding(20px 0 0,null);
			}
			@include respond-below(custom767) {
				@include margin-padding( 0,null);
			}
			.grid-listview {
				@include margin-padding(0 20px 0 0, 0 20px 0 0);
				border-right: 1px solid $__lightgray;
				@include respond-below(custom991) {
					border: 0;
				}
				@include respond-below(custom767) {
					@include margin-padding(20px 0,null);
				}
				.nav {
					@extend %align-items-center;
					li {
						margin-right: 9px;
						&:last-child {
							margin-right: 0;
						}
						a {
							width: 44px;
							height: 44px;
							background: $__white;
							border: 1px solid $__lightgray;
							box-shadow: $__row__box__shadow;
							border-radius: 5px;
							@extend %flex-align-center;
							&:hover {
								@include transition(all 0.5s ease);
								img {
									filter: invert(50%) sepia(15%) saturate(7478%) hue-rotate(100deg) brightness(100%) contrast(50%);
									@include transition(all 0.5s ease);
								}
							}
							&.active {
								img {
									filter: invert(50%) sepia(15%) saturate(7478%) hue-rotate(100deg) brightness(100%) contrast(50%);
								}
							}
						}
					}
				}
			}
			.sortbyset {
				@extend %display-flex-center;
				&.week-bg {
					.sorting-select {
						.select2-container {
							.select2-selection--single {
								.select2-selection__rendered {
									background-image: url(../../../../public/assets/img/icons/calendar-icon.svg);
									background-repeat: no-repeat;
									background-position: 10% 50%;
								}
							}
						}
					}
				}
				.sortbytitle {
					margin-right: 10px;
				}
				.sorting-select {
					width: 168px;
					.select2-container {
						.select2-selection--single {
							height: 50px;
							.select2-selection__rendered {
								padding-right: 30px;
								padding-left: 15px;
							}
							.select2-selection__rendered {
								height: 50px;
								line-height: 43px;
								font-size: $__font__size__15;
								background: $__white;
								border: 3px solid $__lightgray;
								border-radius: 5px;
								color: #192335;
							}
							.select2-selection__arrow {
								height: 50px;
								right: 7px;
								b {
			                        border-top: 0;
			                        border-left: 0;
			                        border-bottom: 2px solid $__secondarycolor;
			                        border-right: 2px solid $__secondarycolor;
			                        width: 9px;
			                        height: 9px;
			                        margin-top: -7px;
			                        display: $__inline__block;
			                        -webkit-transform-origin: 66% 66%;
			                        -ms-transform-origin: 66% 66%;
			                        transform-origin: 66% 66%;
			                        -webkit-transform: rotate(45deg);
			                        -ms-transform: rotate(45deg);
			                        transform: rotate(45deg);
			                        -webkit-transition: all 0.15s ease-in-out;
			                        transition: all 0.15s ease-in-out;
                    			}
							}
						}
					}
					.select2-container--default.select2-container--open {
			            .select2-selection--single {
			                .select2-selection__arrow {
			                    b {
			                        -webkit-transform: rotate(-135deg);
			                        -ms-transform: rotate(-135deg);
			                        transform: rotate(-135deg);
			                        border-color: $__secondarycolor;
			                        border-width: 2px;
			                    }
			                }
			            }
			        }
				}
			}
		}
	}
}

.listing-item-grid {
	@include margin-padding(0 0 24px, null);
	.listing-img {
		img {
		 	width: $__full__width;
		}
	}
	.listing-title {
		white-space: $__nowrap;
		text-overflow: $__ellipsis;
		overflow: $__hidden;
	}
}

.coach-select{
	background: $__white;
	width:30px;
	height: 30px;
	@extend %display-flex-center;
	@extend %justify-content-center;
	border-radius: 50px;
}
.coach-btn {
	border-bottom: 1px solid $__lightgray;
	@include margin-padding(0 0 20px, 0 0 20px);
	ul {
		@extend %display-flex;
		width: $__full__width;
		li {
			padding-right: 15px;
			width: $__half__width;
			@include respond-below(custom1199) {
				padding-right: 10px;
			}
			@include respond-below(custom767) {
				padding-right: 6px;
			}
			&:last-child {
				padding: 0;
			}
			a {
				width: $__full__width;
				@extend %flex-align-center;
				@include respond-below(custom1440) {
					padding: 10px 10px;
				}
				@include respond-below(custom1199) {
					padding: 10px 7px;
					font-size: $__font__size__14;
				}
				@include respond-below(custom767) {
					padding: 10px 3px;
					font-size: $__font__size__14;
				}
			}
		}
	}
}
.avalbity-review {
	ul {
		@extend %display-flex-center;
		@extend %justify-content-between;
	}
	.avalibity-date {
		@extend %display-flex-center;
		@include respond-below(custom767) {
			margin-right: 3px;
		}
		span {
			@extend %display-flex-center;
			@extend %justify-content-center;
			@include margin-padding(0  15px 0 0, null);
			background: $__dull__whitesmoke;
			width: 44px;
			height: 44px;
			border-radius: 10px;
			font-size: $__font__size__20;
			@include respond-below(custom1199) {
				width: 35px;
				height: 35px;
				font-size: $__font__size__16;
				@include margin-padding(0  10px 0 0, null);
			}
			@include respond-below(custom767) {
				width: 32px;
				height: 32px;
				font-size: $__font__size__16;
				@include margin-padding(0  5px 0 0, null);
			}
			i {
				color: $__darkash;
			}
		}
		h6 {
			font-size: $__font__size__14;
			margin-bottom: 4px;
			font-weight: $__medium;
		}
		h5 {
			font-size: $__font__size__16;
			color: $__primarycolor;
			margin: 0;
			font-weight: $__medium;
			@include respond-below(custom1199) {
				font-size: $__font__size__14;
			}
		}
	}
}
.coach-listview {
    display: flex;
	.listing-img{
		width: 26%;
	}
	.listing-content{
		width: 74%;
	}
}
.featured-venues-item {
	&.venue-list-item {
		.listing-item {
			@extend %display-flex;
			.listing-img {
				border-radius: 10px 0 0 10px;
				img {
					border-radius: 10px 0 0 10px;
				}
			}
			.listing-details-group {
				ul {
					@extend %display-flex;
					@include respond-below(custom767) {
						display: $__block;
					}
					li {
						&+li {
							margin-left: 10px;
							padding-left: 10px;
						}
					}
				}
			}
		}
	}
}
.more-details {
	@include margin-padding(16px 0 0 , null)
}
.coach-listview {
	@include respond-below(custom767) {
		display: $__block;
	}
}
.list-reviews {
	&.near-review {
		&.near-review-list {
			padding: 0;
			border: 0;
			margin-bottom: 20px;
			.mile-away {
				font-size: $__font__size__16;
				font-weight: 400;
				span {
					font-size: $__font__size__14;
					font-weight: 400;
				}
			}
		}
	}
}
.avalbity-review {
	&.avalbity-review-list {
		@extend %display-flex-center;
		@extend %justify-content-between;
		border-top: 1px solid $__lightgray;
		padding-top: 15px;
		@include respond-below(custom767) {
			flex-wrap: wrap;
		}
		.profile-coache-list {
			li {
				margin-right: 15px;
				@include respond-below(custom1199) {
					margin-right: 10px;
				}
				&:last-child {
					margin-right: 0;
				}
				.btn {
					@extend %flex-align-center;
					@include respond-below(custom1399) {
						padding: 10px 20px;
						font-size: $__font__size__15;
					}
					@include respond-below(custom1199) {
						padding: 10px 7px;
						font-size: $__font__size__14;
					}
					@include respond-below(custom991) {
						font-size: $__font__size__14;
						padding: 10px 15px;
					}
					@include respond-below(custom767) {
						padding: 10px 10px;
					}
				}
			}
		}
		ul {
			&:first-child {
				@include respond-below(custom767) {
					margin-bottom: 0px;
				}
			}
		}
	}
}
.listing-item {
	&.coach-listview {
		.listing-title {
			margin-bottom: 10px;
		}
		.listing-img {
			border-radius: 10px 0 0 10px;
			@include respond-below(custom767) {
				width: 100%;
				border-radius: 10px 10px 0 0;
			}
			img {
				height: 100%;
				object-fit: cover;
				border-radius: 10px 0 0 10px;
				@include respond-below(custom767) {
					border-radius: 10px 10px 0 0;
				}
			}
		}
		.listing-content {
			@include respond-below(custom767) {
				width: 100%;
			}
		}
	}
}
.listing-list-page {
	.listing-item-grid {
		.listing-img {
			img {
				 width: $__full__width;
				 height: 100%;
				 object-fit: cover;
			}
		}
	}
	.listing-details-info {
		li {
			&:not(:last-child) {
				span {
					&::after {
						color: $__lightgray;
						content: "|";
						font-weight: 300;
						position: relative;
						left: 10px;
					}
				}
			}
		}
	}
	
}

.map-right {
    bottom: 0;
    height: 100%;
    min-height: 100%;
    padding-right: 0px;
    position: sticky;
    right: 0;
    top: 0;
    z-index: 99;
	padding: 0;
	&.map-top {
		top: 0px;
		.map-listing {
			height: calc(100vh - 0px);
		}
	}
    @include respond-below(custom1199) {
        position: relative;
        top: 0;
        padding-right: 15px;
    }
    @include respond-below(custom991) {
        position: relative;
        top: 0;
        padding-right: 15px;
    }
	@include respond-below(custom767) {
        padding-right: 0px;
    }
    .map-listing {
        height: calc(100vh - 200px);
        width: 100%;
		&.venue-map {
			.gm-style .gm-style-iw.gm-style-iw-c {
				padding: 0;
				max-width: 550px !important;
				width: 550px;
				@include respond-below(custom767) {
					max-width: 300px !important;
					width: 240px;
				}
			}
			.gm-style .gm-style-iw-d {
				padding: 0;
				overflow: auto !important;
			}
			.list-map-img  {
				img {
					max-width: 240px;
					max-height: initial;
					border-radius: 10px 0px 0px 10px;
					@include respond-below(custom767) {
						border-radius: 10px 10px 0px 0px;
					}
				}
			}
		}
		.profile-widget {
			max-width: 200px !important;
			padding: 0;
			border: 0;
			margin-bottom: 0;
			font-family: $__body__font__family;
			&.venue-profile-list {
				display: flex;
				width: 100%;
				max-width: 100% !important;
				@include respond-below(custom767) {
					display: block;
				}
				.pro-content {
					padding: 15px;
					.tag {
						padding: 6px 11px;
						border-radius: 5px;
						font-size: 14px;
						min-width: 77px;
						display: $__inline__block;
					}
					.available-info {
						margin-bottom: 0;
						color: $__secondarycolor;
						font-size: $__font__size__14;
						li {
							margin-bottom: 15px;
							&:last-child {
								margin-bottom: 0px;
							}
							i {
								margin-right: 5px;
							}
						}
					}
				}
				.list-map-img {
					border-radius: 10px 0px 0px 10px;
					@include respond-below(custom767) {
						border-radius: 10px 10px 0px 0px;
					}
				}
			}
		}
    }
    .list-map-img {
        position: relative;
        overflow: hidden;
        z-index: 1;
        border-radius: 10px;
		img {
			border-radius: 4px;
			-webkit-transform: translateZ(0);
			-moz-transform: translateZ(0);
			transform: translateZ(0);
			-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
			-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
			-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
			-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
			transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
			width: 100%;
		}
		&:hover {
			img {
				-webkit-transform: scale(1.15);
				-moz-transform: scale(1.15);
				transform: scale(1.15);
			}
		}
    }
	.profile-widget {
		.pro-content {
			padding: 12px 0 0;
			position: relative;
			.title {
				font-size: 20px;
				font-weight: 600;
				margin-bottom: 10px;
				font-family: $__body__font__family;
				a {
					display: inline-block;
					color: $__secondarycolor;
					&:hover {
						color: $__primarycolor;
					}
				}
			}
		}
		.verified {
			color: #28a745;
			margin-left: 8px;
		}
	}
    .profile-widget p.speciality {
        font-size: 14px;
        color: #4f4f4f;
        margin-bottom: 5px;
        min-height: 40px;
    }
    .rating {
        list-style: none;
        margin: 0 0 7px;
        padding: 0;
        width: 100%;
		color: #757575;
        font-size: 14px;
        margin-bottom: 15px;
		i {
			font-size: 14px;
			color: #dedfe0;
			&.filled {
				color: #f4c150;
			}
		}
    }
    .available-info {
        font-size: 13px;
        color: #757575;
        font-weight: 400;
        list-style: none;
        padding: 0;
        margin-bottom: 15px;
		li {
			@extend %display-flex-center;
			&+li {
				margin-top: 5px;
			}
			i {
				width: 15px;
			}
			.list-avail-map {
				margin-bottom: 0;
				span {
					color: $__primarycolor;
				}
			}
		}
    }
}
.map-list-blk::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    border-radius: 50px !important;
}
.map-list-blk::-webkit-scrollbar-track {
    background: $__lightgray;
}
.map-list-blk::-webkit-scrollbar-thumb {
    background: $__darkgray;
    border-radius: 50px;
}
.map-list-blk::-webkit-scrollbar-thumb:hover {
    background: $__darkgray;
}
.content {
	&.map-content {
		padding: 0;
		.map-list-blk {
			padding: 24px 10px;
		}
	}
}
.listing-item {
	.listing-button {
		.btn {
			@include respond-below(custom1024) {
				padding: 10px 12px;
				font-size: $__font__size__14;
			}
		}
	}
}
.listing-item {
	&.coach-listview {
		.avalbity-review {
			&.avalbity-review-list {
				ul {
					.avalibity-date {
						@include respond-below(custom767) {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}
}
.listing-item {
	&.coach-listview {
		.listing-content {
			border-radius: 0 10px 10px 0;
		}
	}
}
.featured-venues-item {
	&.venue-list-item {
		.listing-content {
			border-radius: 0 10px 10px 0;
		}
	}
}
.coach-sidebar-list {
	.profile-coache-list {
		@include respond-below(custom767) {
			margin-top: 15px;
		}
	}
}


