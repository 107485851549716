.sigContainer {
    width: 100%;
    height: 100%;
    margin: 14px auto;
    background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.signatureCanvas {
    border: 1px solid rgb(201, 201, 201);
    border-radius: 4px;
    width: 100%;
    min-height: 200px;
  }
