h2 {
    color: #3D3D3D;
    font-size: 32px;
}
.BlockWrap {
    .imgWrap {
        img {
            width: 180px;
            height: 180px;
            border-radius: 100px;
            border: 1px solid #707070;
            object-fit: cover;
        }
    }
    h3 {
        font-size: 22px;
        margin-bottom: 0;
        color: #3D3D3D;
    }
    p {
        color: #000;
    }
}