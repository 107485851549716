/*-----------------
    26. Events
-----------------------*/

.events-page {
	.listing-item {
		margin-bottom: 24px;
		&:hover {
			.listing-img {
				.date-info {
					background: $__primarycolor;
				}
			}
		}
		.listing-img {
			position: $__relative;
			a {
				img {
					width: 100%;
				}
			}
			.date-info {
				position: $__absolute;
				top: 25px;
				left: 25px;
				background: $__secondarycolor;
				padding: 5px;
				border-radius: 6px;
				width: 90px;
				height: 70px;
				@include transition(all 0.5s ease-in-out 0s);
				h2, h6 {
					color: $__white;
					margin-bottom: 0;
				}
			}
		}
		.listing-content { 
			box-shadow: $__dot__shadow;
			ul { 
				margin-bottom: $__value__10;
				li {
					i {
						margin-right: 5px;
					}
					font-size: $__font__size__14;
					line-height: 10px;
					padding-right: 5px;
					margin-right: 5px;
					&:last-child {
						border-right: none;
						padding-right: 0;
						margin-right: 0;
					}
				}
			}
			.listing-title {
				margin-bottom: 0;
			}
		}
	}
	.content {
		padding-bottom: 24px;
	}
}
.event-details-page {
	.detail-info {
		margin-bottom: 80px;
		.wrapper {
			box-shadow: $__row__box__shadow;
			border-radius: 5px;
			padding: 24px;
			background: $__white;
			.banner {
				position: $__relative;
				img {
					border-radius: 6px;
				}
				.info {
					box-shadow: $__item__box__shadow;
					padding: 24px;
					border-radius: 6px;
					position: $__absolute;
					bottom:-50px;
					left: 5%;
					width: 90%;
					@include respond-below(custom991) {
						position: static;
						width: $__full__width;
						margin-top: 15px;
					}
					.description {
						width: 41.66666667%;
						@include respond-below(custom991) {
							width: $__full__width;
						}
						p {
							margin-bottom: 0;
						}
					}
					.time i, .address i {
						width: $__value__40;
						height: $__value__40;
						border-radius: $__value__40;
						background: $__primarycolor;
						display: inline-block;
						color: $__white;
						margin-right: 8px;
					}
					.time {
						@include respond-below(custom991) {
							margin: 15px 0;
						}
						.text {
							span {
								font-size: 15px;
							}
						}
					}
					.address {
						width: 33.333%;
						@include respond-below(custom991) {
							width: $__full__width;
						}
					}
					h6 {
						color: $__darkgray;
						margin-bottom: 0;
					}
				}
			}
			.seat-booking {
				margin: 90px 0 6px 0;
				.google-maps {
					iframe {
						width: 100%;
						height: 600px;
					}
				}
			}
		}
	}
	.event-booking {
		background: $__whitesmoke;
		form {
			.card { 
				padding: 24px;
			}
			a {
				margin-top: 44px;
			}
		}
	}
	.brand-slider-group { 
		border-top: none;
		padding: 0;
		margin: 0;
	}
}
.mb-10 {
	margin-bottom: $__value__10;
}
.event-details-page {
	.content {
		padding-bottom: 0;
	}
}