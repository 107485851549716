/*-----------------
    36. Coach Profile
-----------------------*/

.profile-court-list {
	margin-bottom: 40px;
	ul {
		@include respond-below(custom991) {
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
		}
		li {
			@include respond-below(custom991) {
				margin-bottom: 20px;
			}
			&:last-child {
				@include respond-below(custom767) {
					margin-bottom: 0px;
				}
			}
			a {
				@include respond-below(custom767) {
					width: 100%;
				}
			}
		}
	}
}
.profile-detail-blk {
    border-bottom: 1px solid $__bright__gray;
	margin-bottom: 40px;
	ul {
		@extend %display-flex;
		flex-wrap: wrap;
		li {
			padding-bottom: 10px;
			position: relative;
			margin-right: 20px;
			&:last-child {
				a {
					padding-right: 0px;
				}
			}
			@include respond-below(custom767) {
				margin-right: 0px;
				margin-bottom: 15px;
			}
			a {
				padding-right: 20px;
				color: $__secondarycolor;
				font-size: $__font__size__20;
				font-weight: 600;
				@include respond-below(custom991) {
					font-size: $__font__size__18;
				}
			}
		}
		.active {
			a {
				color: $__primarycolor;
			}
			&::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 2px;
				background: $__primarycolor;
				bottom: 0;
				border-radius: 5px;
			}
		}
	}
}
.input-space {
	margin-bottom: 24px;
	&.link-apoint {
		margin-bottom: 15px;
		.input-box {
			border-bottom: 1px solid $__bright__gray;
			padding-bottom: 15px;
			p {
				font-size: $__font__size__14;
				padding-top: 15px;
				margin-bottom: 0;
			}
		}
	}
	&.other-setting-form {
		border-bottom: 1px solid $__bright__gray;
		padding-bottom: 15px;
		margin-bottom: 15px;
		.form-control {
			max-width: 612px;
		}
	}
}
.profile-detail-group {
	.card {
		padding: 24px;
		box-shadow: $__row__box__shadow;
		border: 0;
		border-radius: 10px;
		margin-bottom: 40px;
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__rendered {
				color: $__darkgray;
			}
			.select2-selection__arrow {
				height: 60px;
				top: 0;
				b {
					border: 0;
					background-image: url(../../../../public/assets/img/icons/down-arrow.svg);
					width: 11px;
					height: 6px;
					@include transition(all 0.5s ease);
				}
			}
		}
		&.select2-container--open {
			.select2-selection--single {
				.select2-selection__arrow b {
					transform: rotate(-180deg);
					-webkit-transform: rotate(-180deg);
					@include transition(all 0.5s ease);
				}
			}
		}
	}
}
.info-about {
	padding-bottom: 24px;
	margin-bottom: 24px;
	border-bottom: 1px solid $__bright__gray;
}
.address-form-head {
	h4 {
		margin-bottom: 24px;
	}
}
.save-changes {
	.btn {
		&:first-child {
			margin-right: 15px;
		}
	}
	.reset-profile {
		min-width: 103px;
		padding: 12px 15px;
	}
	.save-profile {
		min-width: 144px;
		padding: 12px 15px;
	}
}
.file-upload-text { 
	margin-bottom: 45px;
	&.appointment-upload {
		margin-bottom: 15px;
		.file-upload {
			width: 100%;
			#file-input {
				width: $__full__width;
			    height: inherit;
			    position: $__absolute;
			    left: 0;
			    top: 0;
			    cursor: pointer;
			    opacity: 0;
			}
		}
		h5 {
			border-bottom: 1px solid $__bright__gray;
			padding-bottom: 15px;
		}
	}  
	.file-upload {
		background: $__white;
		border: 1px dashed $__dull__text;
		border-radius: 10px;
		font-size: $__font__size__14;
		font-weight: 400;
		width: 182px;
		height: 163px;
		padding: 45px 20px;
		text-align: $__center;
		position: $__relative;
		margin-bottom: 20px;
		@include respond-below(custom767) {
			@include margin-padding(0 auto 15px, null);
		}
		img {
			margin-bottom: 8px;
		}
		p {
			color: $__darkgray;
			margin-bottom: 0;
		}
		span {             
			@include position($__absolute, 10px, 10px, null, null);
			cursor: $__pointer;
			width: 40px;
			height: 40px;
			background: $__primarycolor;
			color: $__white;
			border-radius: 50%;
			@extend %flex-align-center;
			input[type="file"] {             
				@include position($__absolute, 0, null, null, 0);
				width:  $__full__width;
				height: $__full__height;
				opacity: 0;
				cursor: $__pointer;
			}
		}
	}
	h5 {
		font-size: $__font__size__14;
		font-weight: 400;
		color: $__darkgray; 
		@include respond-below(custom767) {
			text-align: $__center;
		}
	}
}
.appoint-head {
	h4 {
		margin-bottom: 15px;
	}
}
.upload-show-img {
	@extend %display-flex;
	margin-bottom: 15px;
	flex-wrap: $__wrap;
	.upload-images {
		position: $__relative;
		margin-right: 10px;
		@include respond-below(custom767) {
			margin-bottom: 10px;
		}
		&:last-child {
			margin-right: 0;
		}
		img {
			width: 100px;
			height: 100px;
			border-radius: 10px;
		}
		.btn-icon {
			text-align: $__center;
			position: $__absolute;
			padding: 0;
			font-size: 12px;
			width: 34px;
			height: 34px;
			right: 3px;
			top: 3px;
			color: $__white;
			border-radius: 50px;
			@extend %flex-align-center;
			background: $__dark__red;
			&:hover {
				background: $__pink;
			}
		}
	}
}
.check-single-lesson {
	@extend %display-flex;
	margin-top: 15px;
	flex-wrap: $__wrap;
	.similar-player {
		margin-right: 70px;
		@include respond-below(custom767) {
			margin-right: 0px;
			margin-bottom: 15px;
		}
		.form-check {
			margin-bottom: 10px;
			.form-check-input[type=checkbox] {
				cursor: pointer;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
		ul {
			li {
				font-size: $__font__size__14;
				color: $__darkgray;
				margin-bottom: 0;
				span {
					color: $__primarycolor;
				}
			}
		}
	}
}

.coach-top {
	border-top: 1px solid $__bright__gray;
	padding-top: 15px;
}
.prefer-role {
    @extend %display-flex;
	@extend %justify-content-between;
	margin-bottom: 40px;
	&:last-child {
		margin-bottom: 0;
	}
    @include respond-below(custom575) {
        display: $__block;
    }
    .prefer-list {
        @extend %display-flex-center;
		@include respond-below(custom767) {
			display: $__block;
        }
        @include respond-below(custom575) {
            margin-bottom: 15px;
        }
        .toggle-switch {
            @include margin-padding(0 30px 0 0, null);
            @include respond-below(custom767) {
                margin-right: 10px;
            }
        }
        .role-selection {
            margin-right: 20px;
            @include respond-below(custom767) {
                margin-right: 0;
				margin-bottom: 15px;
            }
            label {
                min-width: 200px;
                display: $__block;
                text-align: $__center;
                margin-bottom: 0;
				padding: 21px 15px;
                @include respond-below(custom767) {
                    min-width: 200px;
					padding: 14px 15px;
                }
                .role-check {
                    float: $__right;
                }
            }
        }
    }
    .less-info {
        h6, p {
            font-size: $__font__size__16;
            margin-bottom: 0;
            color: $__darkgray;
			font-weight: 400;
			@include respond-below(custom767) {
				font-size: $__font__size__14;
			}
            span {
                color: $__primarycolor;
            }
        }
    }
	.interset-btn {
		.status-toggle {
			border: 0;
			margin-bottom: 0px;
			padding: 0;
			.checktoggle {
				margin: 0;
			}
		}
	}
}

.role-selection {
    input {
        width: 100%;
        position: absolute;
        left: 0;
        opacity: 0;
        &:checked {
            ~ label, ~ .role-label {
                border: 1px solid $__bright__gray;
                background: $__secondarycolor;
                .role-check {
                    display: $__inline__block;
                    float: $__right;
                }
            }
        }
    }
    label, .role-label {
        border: 1px solid $__bright__gray;
        border-radius: 5px;
        @include margin-padding(0 0 20px, 20px);  
        @extend %justify-content-between;
        @include respond-below(custom1399) {
            @include margin-padding(0 0 20px, 15px);
        }
        @include respond-below(custom991) {
            @include margin-padding(0 0 20px, 10px);
        }
        .role-name {
            font-size: $__font__size__16;
            font-weight: $__medium;
            @include respond-below(custom767) {
                font-size: $__font__size__14;
            }
            img {
                width: 40px;
                margin-right: 25px;
                @include respond-below(custom767) {
                    width: 30px;
                    margin-right: 10px;
                }
            }
        }
        .role-check {
            color: $__success;
            display: $__none;
        }
    }
}
.preference-head {
	h4 {
		color: $__secondarycolor;
		margin-bottom: 20px;
	}
}

.profile-availability {
	.select-days {
		h4 {
			margin-bottom: 10px;
		}
	}
	.day-list {
		li {
			display: $__inline__block;
				.day-selection {
				position: $__relative;
				input {
					width: $__full__width;                
					@include position($__absolute, null, null, null, 0);
					opacity: 0;
					cursor: pointer;
					&:checked {
						~ label {
							border: 2px solid $__primarycolor;
							color: $__primarycolor;
							font-size: $__font__size__14;
							font-weight: 500;
							.role-check {
								display: $__inline__block;
								float: $__right;
							}
						}
					}
				}
				label {
					border: 2px solid $__lightgray;
					border-radius: 5px;
					@include margin-padding(0 20px 20px 0, 12px); 
					min-width: 92px;
					font-weight: 500;
					color: $__secondarycolor;
					font-size: $__font__size__14;
					text-align: $__center;
					@include respond-below(custom1399) {
						@include margin-padding(0 20px 20px 0, 12px); 
					}
					@include respond-below(custom991) {
						@include margin-padding(0 0 20px, 10px);
					}
				}
			}
		}
	}
	
	.form-check-inline {
		display: $__inline__block;
	}
	.visits {
		.visit-btns {
			color: $__secondarycolor;
			background-color: $__white;
			width: $__full__width;
			margin-bottom: 10px;
			display: $__block;
			outline: $__unset;
			cursor: $__pointer;
		}
	}
	.visits input:checked ~ .visit-rsn {
		border-color: $__primarycolor;
		color: $__primarycolor;
	}
	.visits input:checked ~ .visit-rsn:before {
		opacity: 1;
	}
	.visits input:disabled ~ .visit-rsn {
		color: $__greycolor;
		border-radius: 5px;
	}
	.visits .form-check-input {
		opacity: 0;
	}
	.visits input.form-check-input {             
		@include position($__absolute, 0, null, null, 0);
		opacity: 0;
		visibility: $__hidden;
		margin-left: 0;
	}
	.visits span.visit-rsn {
		background-color: #F3F9FF;
		border: 0;
		border-radius: 4px;
		color: #64627C;
		display: $__block;
		font-size: $__font__size__14;
		margin-bottom: 0px;
		padding: 13px 5px;
		text-align: $__center;
		position: $__relative;
		font-weight: $__semibold;
	}
	.visits span.visit-rsn {
		text-align: center;
		padding: 12px 5px;
		background: $__white;
		border: 1px solid $__bright__gray;
		border-radius: 5px;
		min-width: 130px;
		display: $__inline__block;
		width: 100%;    
		@include respond-below(custom767) { 
			min-width: 108px;
		}
	}
	
	.setting-accordion {
		.accordion-item {
			margin-bottom: 24px;
			background: $__white;
			border: 0;
			border-radius: 5px;
			box-shadow: $__row__box__shadow;
		}
		.accordion-header {    
			cursor: $__pointer;
			.accordion-button {
				background: transparent;
				@include margin-padding(null, 20px);
				.toggle-switch {
					margin-right: 15px;                
					@include respond-below(custom767) {
						margin-right: 7px;
					}
				}
				.accord-title {
					font-size: $__font__size__24;
					font-weight: 600;
					color: $__secondarycolor;
					margin-left: 10px;
					@include respond-below(custom991) {
						font-size: $__font__size__20;
					}
				}
				a {
					margin-left: $__auto;
					font-weight: 400;
					color: $__lightash;
					font-size: $__font__size__14;
				}
				&:focus {
					box-shadow: $__none;
					border-color: $__bright__gray;
				}
				&:after {
					content: $__none;
				}
				&:not(.collapsed) {
					background: $__white;
					color: $__secondarycolor;
					border-bottom: 1px solid $__bright__gray;
					box-shadow: $__none;
					.accord-title {
						color: $__secondarycolor;
					}
					a {
						color: $__primarycolor;
					}
				}
			}
			.accordion-body {
				@include margin-padding(null, 20px);
			}
		}
	}
	.form-icon {
		position: $__relative;
		width: $__full__width;
		&:after {
			color: #344357;
			content: "\f078";
			display: $__block;
			font-family: "FontAwesome";
			font-size: $__font__size__12;
			margin: $__auto;             
			@include position($__absolute, 50%, 15px, null, null);
			transform: translateY(-50%);
		}
	}
	.save-time {
		margin-top: 0px;
		a {
			color: $__red;
			font-weight: $__semibold;
			font-size: $__font__size__14;
		}
		&.add-save-time {
			margin-top: 15px;
		}
	}
	#day-monday {
		display: $__block;
	}
	#day-tuesday, #day-wednesday, #day-thursday, #day-friday, #day-saturday, #day-sunday{
		display: $__none;
	}
	.card-icon {
		position: $__relative;
		.form-control {
			@include margin-padding(null, 10px 160px 10px 10px);
		}
		span {             
			@include position($__absolute, 50%, 10px, null, null);
			transform: translateY(-50%);
		}
	}
}

.interset-btn {
	&.empty-space {
		.status-toggle {
			padding: 0;
			margin: 0;
			border: 0;
		}
		.checktoggle {
			margin: 0;
		}
	}
}
.duration-blk {
	margin-bottom: 15px;
	label {
		margin-bottom: 10px;
		color: $__secondarycolor;
		font-size: $__font__size__14;
		span {
			&.text-danger {
				color: $__red !important;
			}
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__rendered {
				color: $__darkgray;
				border-radius: 5px;
			}
			.select2-selection__arrow {
				height: 58px;
				top: 0;
				b {
					border: 0;
					background-image: url(../../../../public/assets/img/icons/down-arrow.svg);
					width: 11px;
					height: 6px;
					@include transition(all 0.5s ease);
				}
			}
		}
		&.select2-container--open {
			.select2-selection--single {
				.select2-selection__arrow b {
					transform: rotate(-180deg);
					-webkit-transform: rotate(-180deg);
					@include transition(all 0.5s ease);
				}
			}
		}
	}
	.form-control {
		background: $__dull__whitesmoke;
		border-radius: 5px;
		border: 0;
		height: 58px;
		&:focus {
			box-shadow: none;
		}
	}
}
.deactivate-account-blk {
	@extend %justify-content-between;
	@extend %display-flex-center;
	@include respond-below(custom767) {
		display: $__block;
	}
	.deactivate-detail {
		@include respond-below(custom767) {
			margin-bottom: 15px;
		}
		h4 {
			margin-bottom: 5px;
		}
		p {
			font-size: $__font__size__14;
			margin-bottom: 0;
		}
	}
}
.deactive-btn {
	background: $__red;
	border-radius: 10px;
	padding: 12px 15px;
	color: $__white;
	min-width: 196px;
	&:hover {
		background: $__secondarycolor;
	}
	i {
		margin-right: 5px;
	}
}
.deactive-modal {
	.modal-header {
		border: 0;
		padding: 0;
		position: $__absolute;
		right: 20px;
		top: 20px;
		z-index: 99;
	}
	.modal-body {
		padding: 40px;
	}
	.account-deactive {
		max-width: 300px;
		margin: auto;
		text-align: center;
		img {
			margin-bottom: 30px;
		}
		h3 {
			margin-bottom: 15px;
		}
		p {
			margin-bottom: 24px;
			font-size: $__font__size__14;
		}
		.convenient-btns {
			.btn {
				padding: 12px 20px;
				&:first-child {
					margin-right: 24px;
				}
				span {
					@extend %display-flex-center;
				}
			}
		}
	}
}

.my-profile-box {
	h3 {
		margin-bottom: 40px;
	}
}
.profile-user-view {
	&.card {
		border: 0;
		border-radius: 10px;
		box-shadow: $__row__box__shadow;
		margin-bottom: 24px;
		padding: 25px;
	}
}
.profile-groups {
	@extend %display-flex-center;
	@extend %justify-content-between;
	flex-wrap: $__wrap;
	.profile-detail-box {
		@extend %display-flex-center;
		flex-wrap: $__wrap;
		.profile-img {
			@include respond-below(custom767) {
				margin-bottom: 15px;
			} 
			img {
				width: 100px;
				height: 100px;
				margin-right: 15px;
			}
		}
		.user-profile-detail {
			@include respond-below(custom767) {
				margin-bottom: 15px;
			} 
			h4 {
				margin-bottom: 5px;
			}
			p {
				font-size: $__font__size__14;
				margin-bottom: 5px;
			}
			ul {
				@extend %display-flex-center;
				flex-wrap: $__wrap;
				li {
					font-size: $__font__size__14;
					@include respond-below(custom767) {
						margin-bottom: 10px;
					} 
					&:last-child {
						@include respond-below(custom767) {
							margin-bottom: 0px;
						} 
					}
					&+li {
						margin-left: 20px;
						@include respond-below(custom767) {
							margin-left: 0px;
						} 
					}
					img {
						margin-right: 5px;
					}
				}
			}
		}
	}
	.convenient-btns {
		.btn {
			i {
				margin-right: 5px;
			}
		}
	}
}
.profile-info-box {
	border-bottom: 1px solid $__bright__gray;
	@include margin-padding(0 0 15px 0, 0 0 15px 0);
	p {
		margin-bottom: 0;
		@include respond-below(custom1440) {
			font-size: $__font__size__15;
		}
	}
	h4 {
		margin-bottom: 15px;
	}
	.profile-contact-info {
		@extend %display-flex-center;
		@extend %justify-content-between;
		flex-wrap: $__wrap;
		.contact-information {
			@include respond-below(custom767) {
				margin-bottom: 15px;
			}
			&:last-child {
				@include respond-below(custom767) {
					margin-bottom: 0px;
				}
			}
			h6 {
				margin-bottom: 5px;
				font-weight: 500;
			}
			span {
				@include respond-below(custom1440) {
					font-size: $__font__size__15;
				}
			}
		}
	}
	ul {
		@extend %display-flex;
		li {
			&+li {
				margin-left: 24px;
				@include respond-below(custom1440) {
					margin-left: 15px;
				}
			}
			img {
				width: 100px;
				height: 100px;
				border-radius: 10px;
			}
		}
	}
	&:last-child {
		border: 0;
		margin: 0;
		padding: 0;
	}
	.badminton-img {
		iframe {
			border-radius: 10px;
			width: 100%;
			border: 0;
		}
	}
}
.appointment-detail {
	@extend %display-flex-center;
	@extend %justify-content-between;
	margin-bottom: 24px;
	a {
		font-weight: 500;
	}
}
.appointment-available {
	border: 1px solid $__bright__gray;
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 24px;
	&:last-child {
		margin-bottom: 0;
	}
	&.profile-availability {
		.day-list {
			li {
				.day-selection {
					label {
						margin: 0 15px 15px 0;
						min-width: 97px;
						padding: 11px;
						@include respond-below(custom767) {
							min-width: 90px;
						}
					}
				}
			}
		}
	}
	.amount-hour {
		@extend %display-flex-center;
		@extend %justify-content-between;
		h6 {
			margin-bottom: 0;
		}
		h4 {
			color: $__primarycolor;
			margin-bottom: 0;
			span {
				font-size: $__font__size__14;
				color: $__lightash;
				font-weight: 400;
			}
		}
	}
	h6 {
		margin-bottom: 24px;
	}
	.lesson-type {
		display: inline-grid;
		li {
			padding: 10px;
			background: $__dull__whitesmoke;
			border-radius: 10px;
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
			i {
				margin-right: 5px;
			}
		}
	}
}