/*-----------------
    31. Dashboard
-----------------------*/

.dashboard-section {
	background: $__whitesmoke;
	overflow: $__hidden;
	@include margin-padding(null, 24px 0 9px);
	@include respond-below(custom767) {
		@include margin-padding(null, 24px 0 14px);
	}
	.dashboard-menu {
		@include respond-below(custom991) {
			text-align: $__center;
		}
		&.coaurt-menu-dash {
			ul {
				li {
					a {
						min-width: 137px;
						@include margin-padding(null, 20px);
						@include respond-below(custom1440) {
							min-width: 160px;
							@include margin-padding(null, 30px);
						}
						@include respond-below(custom767) {
							min-width: 136px;
							@include margin-padding(null, 20px);
						}
					}
				}
			}
		}
		ul {
			@include respond-below(custom991) {
				margin-right: -24px;
			}
			@include respond-below(custom767) {
				margin-right: -10px;
			}
			li {
				display: $__inline__block;
				margin-bottom: 24px;
    			margin-right: 24px;
    			@include respond-below(custom1440) {
    				margin-right: 15px;
					margin-bottom: 15px;
    			}
    			@include respond-below(custom991) {
    				margin-right: 24px;
    			}
    			@include respond-below(custom767) {
    				margin-right: 10px;
    				margin-bottom: 10px;
    			}
    			&:last-child {
    				margin-right: 0;
    				@include respond-below(custom991) {
	    				margin-right: 24px;
	    			}
	    			@include respond-below(custom767) {
	    				margin-right: 10px;
	    			}
    			}
    			a {
    				color: $__secondarycolor;
    			    background: $__white;
				    border: 1px solid $__lightgray;
				    font-weight: $__medium;
				    border-radius: 10px;
					@include transition(all 0.5s ease-in-out 0s);
				    @include margin-padding(null, 35px);
				    display: $__inline__block;
				    text-align: $__center;
				    min-width: 190px;
				    line-height: $__normal;
				    @include respond-below(custom1440) {
	    				min-width: 170px;
	    			}
	    			@include respond-below(custom767) {
	    				min-width: 135px;
	    				@include margin-padding(null, 20px);
	    			}
				    img {
				    	margin-bottom: 10px;
				    }
				    span {
				    	display: $__block;
				    }
				    &.active {
				    	color: $__white;
				    	background: $__primarycolor;
						border: 1px solid $__primarycolor;
						img {
							filter: invert(1) brightness(100);
						}
						&:hover {
					    	color: $__white;
					    	background: $__primarycolor;
							border: 1px solid $__primarycolor;
					    }
				    }
				    &:hover {
				    	color: $__white;
				    	background: $__primarycolor;
						border: 1px solid $__primarycolor;
						@include transition(all 0.5s ease-in-out 0s);
						img {
							filter: invert(1) brightness(100);
							@include transition(all 0.5s ease-in-out 0s);
							&:hover {
								@include transition(all 0.5s ease-in-out 0s);
							}
						}
				    }
    			}
			}
		}
	}
}
.dashboard-card {
	background: $__white;
	box-shadow: $__row__box__shadow;
	width: 100%;
	@include margin-padding(0 0 24px, 24px 24px 0);
	border: 0;
	@include respond-below(custom767) {
		@include margin-padding(0 0 20px, 20px 20px 0);
	}
	.card-header {
		background: $__transparent;
		border-bottom: 1px solid $__lightgray;
		@include margin-padding(0 0 15px, 0 0 15px);
		h4 {
			margin-bottom: 5px;
			@extend %justify-content-start;
		}
		p {
			margin-bottom: 0;
		}
	}
	.card-header-info {
		@extend %display-flex-center;
		@extend %justify-content-between;
		@include margin-padding(0, 0 0 15px);
		@include respond-below(custom767) {
			display: $__block;
		}
		.card-header-inner {
			@include respond-below(custom767) {
				padding-bottom: 10px;
			}
		}
	}
	&.statistic-simply {
		.card-header-info {
			@include respond-below(custom767) {
				display: flex;
			}
		}
	}
}
.card-header-btns {
	padding: 10px;
	background: $__light__whitesmoke;
	border-radius: 10px;
	flex-shrink: 0;
	@include respond-below(custom767) {
		display: $__inline__block;
	}
	.btn {
	    font-size: $__font__size__14;
	    padding: 8px 10px;
	    color: $__secondarycolor;
	    font-weight: $__regular;
	    border: 1px solid $__transparent;
		&.active {
			color: $__white;
			background: $__primarycolor;
			border-color: $__primarycolor;
			&:hover {
				color: $__white;
				background: $__primarycolor;
				border-color: $__primarycolor;
			}
		}
		&:hover {
			color: $__primarycolor;
			background: $__white;
			border-color: $__white;
		}
	}
}
.statistics-card {
	border: 1px solid $__lightgray;
	box-shadow: $__inherit;
	width: 100%;
}
.statistics-grid {
	background: $__dull__whitesmoke;
	@include margin-padding(0 0 24px, 24px);
	@include respond-below(custom767) {
		@include margin-padding(0 0 20px, 20px);
	}
	@extend %display-flex-center;
	@extend %justify-content-between;
	border-radius: 10px;
	.statistics-content {
		h3 {
			color: $__primarycolor;
			margin-bottom: 5px;
			@include respond-below(custom1199) {
				font-size: $__font__size__20; 
			}
			@include respond-below(custom767) {
				font-size: $__font__size__20; 
			}
		}
		p {
			font-weight: $__medium;
			line-height: $__normal;
			margin-bottom: 0;
		}
	}
	.statistics-icon {
		background: $__white;
		box-shadow: $__row__box__shadow;
		@extend %flex-align-center;
		min-width: 70px;
		height: 70px;
		border-radius: 5px;
	}
}
.appointment-info {
	ul {
		@extend %equal-heights;
		@include respond-below(custom767) {
			display: $__block;
		}
		&.appointmentset{
			li{
				margin: 0;
				width: 25%;
				@include respond-below(custom991) {
					width: 50%;         
					margin-bottom: 15px !important;
				}
				@include respond-below(custom575) {
					width: 100% !important;         
				}
			}
			&.appointmentset-cancel{
				li{
					margin: 0;
					width: 33.3%;
					@include respond-below(custom991) {
						width: 50%;         
						margin-bottom: 15px !important;
					}
					@include respond-below(custom575) {
						width: 100% !important;         
					}
				}
			}
		}
		
		&.appointmentsetview{
			li{
				margin: 0;
				width: 20%;
				@include respond-below(custom991) {
					width: 50%;         
					margin-bottom: 15px !important;
				}
				@include respond-below(custom575) {
					width: 100% !important;         
				}
			}
		}
		li {
			margin-right: 70px;
			margin-bottom: 24px;
			@include respond-below(custom1440) {
				margin-right: 50px;
			}
			@include respond-below(custom767) {
				margin-bottom: 20px;
			}
			&:last-child {
				margin-right: 0;
			}
			.appointment-item {
				@extend %display-flex-center;
				.appointment-img {
					min-width: 45px;
					height: 45px;
					border-radius: 10px;
					margin-right: 10px;
					img {
						min-width: 45px;
						height: 45px;
						border-radius: 10px;
					}
				}
			}
			h6 {
				margin-bottom: 5px;
			}
			p {
				margin-bottom: 0;
				font-size: 14px;
				color: #6B7385;
				&.color-green {
					color: #097E52;
				}
			}
		}
	}
}
.dashboard-table-responsive {
	padding-bottom: 9px;
}
.dashboard-card-table {
	tbody {
		tr {
			border-bottom: 1px solid $__lightgray;
			&:last-child {
				border-bottom: 0;
			}
			td {
				border: 0;
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
					text-align: $__right;
				}
				h6 {
					margin-bottom: 15px;	
				}
				p {
					color: $__darkgray;
					margin-bottom: 0;	
				}
				h4 {
					color: $__primarycolor;
					margin-bottom: 0;	
				}
			}
		}
	}
	&.table-borderless {
		p {
			color: $__secondarycolor;
			margin-bottom: 5px;	
			&:last-child {
				margin-bottom: 0;
			}
		}
		h6 {
			margin-bottom: 0;	
		}
	}
}
.academy-info {
	@extend %display-flex-center;
	&.academy-coach-info {
		.academy-img {
			min-width: 50px;
			height: 50px;
			img {
				min-width: 50px;
				height: 50px;
			}
		}
	}
	.academy-img {
		min-width: 80px;
		height: 80px;
		border-radius: 10px;
		img {
			min-width: 80px;
			height: 80px;
			border-radius: 10px;
		}
	}
	.academy-content {
		margin-left: 15px;
		h6 {
			margin-bottom: 8px;
			a {
				color: $__secondarycolor;
				&:hover {
					color: $__primarycolor;
				}
			}
		}
		span {
			color: $__primarycolor;
			display: $__block;
			margin-bottom: 10px;
		}
		ul {
			@extend %display-flex-center;
			li {
				color: $__darkgray;
				margin-right: 10px;
				padding-right: 10px;
				border-right: 1px solid $__lightgray;
				@extend %display-flex-center;
				&:last-child {
					margin-right: 0;
					padding-right: 0;
					border: 0;
				}
				i {
					margin-right: 5px;
				}
			}
		}
	}
}
.payment-card {
	background: $__primarycolor;
	box-shadow: $__inherit;
	padding-bottom: 0;
	margin-bottom: 15px;
	position: $__relative;
	overflow: $__hidden;
	&::after {
		content: "";
		position: $__absolute;
		width: 208px;
    	height: 208px;
    	background: $__light__green;
    	border-radius: 50%;
	    top: 75px;
	    left: 50%;
	    transform: translateX(-50%);
	    -webkit-transform: translateX(-50%);
	}
	.payment-info {
		@extend %display-flex-center;
		@extend %justify-content-between;
		padding-bottom: 24px;
		@include respond-below(custom767) {
			padding-bottom: 20px;
		}
		.payment-content {
			p {
				font-size: $__font__size__14;
				color: $__white;
				margin-bottom: 10px;
			}
			h2 {
				color: $__white;
				margin-bottom: 0;
			}
		}
		.payment-btn {
			.btn {
				font-size: $__font__size__14;
				font-weight: $__regular;
				color: $__active_color;
				border: 1px solid $__active_color;
				border-radius: 5px;
				padding: 8px 10px;
				background: $__transparent;
				&:hover {
					color: $__primarycolor;
					border-color: $__active_color;
					background: $__active_color;
				}
			}
		}
	}
}
.upcoming-card {
	box-shadow: $__inherit;
	margin-bottom: 15px;
	.academy-info {
		.academy-img {
			min-width: 50px;
		    height: 50px;
		    border-radius: 10px;
			img {
				min-width: 50px;
			    height: 50px;
			    border-radius: 10px;
			}
		}
		.academy-content {
			margin-left: 15px;
			ul {
				line-height: $__normal;
				li {
					margin-right: 8px;
    				padding-right: 8px;
    				@extend %display-flex-center;
    				&:last-child {
    					margin-right: 0;
    					padding-right: 0;
    				}
    				i {
    					margin-right: 7px;
    				}
				}
			}
		}
	}
}
.academy-card {
	.academy-info {
		.academy-img {
			min-width: 50px;
		    height: 50px;
		    border-radius: 10px;
			img {
				min-width: 50px;
			    height: 50px;
			    border-radius: 10px;
			}
		}
		.academy-content {
			margin-left: 15px;
			ul {
				line-height: $__normal;
				li {
					margin-right: 8px;
    				padding-right: 8px;
    				@extend %display-flex-center;
    				&:last-child {
    					margin-right: 0;
    					padding-right: 0;
    				}
    				i {
    					margin-right: 7px;
    				}
				}
			}
		}
	}
	.academy-icon {
		a {
			color: $__lightash;
			font-size: $__font__size__24;
			&:hover {
				color: $__primarycolor;
			}
		}
	}
}
.court-bg {
	background: $__light__whitesmoke;
}
.coach-court-list {
	@include respond-below(custom767) {
		margin-bottom: 20px;
	}
	ul {
		@include respond-below(custom991) {
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
		}
		li {
			@extend %display-flex;
			@include respond-below(custom767) {
				width: 100%;
				margin-bottom: 15px;
			}
			&+li {
				margin-left: 20px;
				@include respond-below(custom767) {
					margin-left: 0px;
				}
			}
			a {
				padding: 13px 30px;
				border-radius: 5px;
				background: $__white;
				color: $__darkgray;
				@include respond-below(custom1199) {
					padding: 10px 12px;
				}
				@include respond-below(custom991) {
					display: $__block;
				}
				@include respond-below(custom767) {
					width: 100%;
				}
				&.active {
					background: $__secondarycolor;
					color: $__white;
				}
				&:hover {
					background: $__secondarycolor;
					color: $__white;
				}
			}
		}
	}
}
.sortby-filter-group {
	&.court-sortby {
		.sorting-select {
			position: relative;
		}
		.sortbyset {
			&:first-child {
				margin-right: 20px;
				@include respond-below(custom767) {
					margin-right: 0px;
					margin-bottom: 20px;
				}
			}
		}
		.select-icon {
			position: absolute;
			top: 45%;
			left: 16px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			z-index: 99999;
			color: $__lightash;
		}
	}
}
.sortby-section {
	.sorting-info {
		.sortby-filter-group {
			&.court-sortby {
				.sortbyset {
					&:first-child {
						.sorting-select {
							width: 140px;
							@include respond-below(custom767) {
								width: 100%;
							}
						}
						.select2-selection--single {
							.select2-selection__rendered {
								padding-left: 35px;
								font-size: $__font__size__14;
								color: #192335;
							}
						}
					}
				}
			}
		}
	}
	&.court-sortby-section {
		.sorting-info {
			background: $__whitesmoke;
			border: 0;
			border-radius: 5px;
			box-shadow: none;
			.sortby-filter-group {
				@include respond-below(custom991) {
					justify-content: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
				}
			}
		}
	}
}
.court-tab-content {
	.card {
		border: 0;
		padding: 24px;
		box-shadow: $__row__box__shadow;
		@include respond-below(custom767) {
			padding: 20px;
		}
	}
	.card-body {
		padding: 0;
		.coache-head-blk {
			margin-bottom: 24px;
			.form-control {
				background: $__light__whitesmoke;
				border: 0;
				padding: 20px;
				height: 58px;
				outline: 0;
				&:focus {
					box-shadow: none;
				}
				&::placeholder {
					color: $__lightash;
					font-size: $__font__size__16;
				}
			}
			.dataTables_filter {
				label {
					margin-bottom: 0;
					float: $__right;
				}
				+ .dataTables_filter{
					display: $__none;
				}
			}
			
			.request-coach-list {
				ul {
					background: $__light__whitesmoke;
					border-radius: 10px;
					padding: 10px;
					li {
						&+li {
							margin-left: 10px;
						}
						a {
							padding: 10px 15px;
							@extend %display-flex;
							border-radius: 10px;
							&.active {
								background: $__primarycolor;
								color: $__white;
							}
							&:hover {
								background: $__primarycolor;
								color: $__white;
							}
						}
					}
				}
			}
		}
		.text-pink {
			a {
				color: $__pink;
				@extend %display-flex-center;
				&:hover {
					color: $__primarycolor;
				}
			}
		}
		.view-detail-pink {
			i {
				margin-right: 5px;
			}
		}
		.table-inset-btn {
			.interset-btn {
				.status-toggle {
					padding: 0;
					border: 0;
					margin-bottom: 0;
				}
			}
		}
		.table-drop-action {
			.action-icon {
				&::after {
					display: $__none;
				}
			}
			.dropdown-menu {
				background: $__white;
				box-shadow: $__dot__shadow;
				border-radius: 10px;
				border: 0;
				padding: 0;
				.dropdown-item {
					font-size: $__font__size__14;
					color: $__secondarycolor;
					border-bottom: 1px solid $__lightgray;
					padding: 10px 15px;
					&:hover {
						background-color: #fafafa;
						color: $__primarycolor;
					}
					&:first-child {
						&:hover {
							border-radius: 10px 10px 0 0;
						}
					}
					&:last-child {
						&:hover {
							border-radius: 0 0 10px 10px;
						}
					}
					&:last-child {
						border: 0;
					}
					i {
						margin-right: 5px;
					}
				}
			}
		}
	}
	.tab-footer{
		.dataTables_paginate {
			+ .dataTables_paginate {
				display: none;
			}
		}
		.dataTables_length {
			+ .dataTables_length {
				display: none;
			}
		}
	}
}
.coach-active-blk {
	@extend %display-flex-center;
	@extend %justify-content-end;
	@include respond-below(custom991) {
		margin-top: 15px;
	}
	@include respond-below(custom767) {
		flex-wrap: wrap;
	}
	.card-header-btns {
		margin-left: 15px;
		@include respond-below(custom767) {
			margin-left: 0px;
			margin-top: 15px;
		}
	}
}
.court-table-head {
	h4 {
		margin-bottom: 5px;
	}
	p {
		color: $__darkgray;
		margin-bottom: 0;
	}
}
.court-notify {
	width: 25px;
	height: 25px;
	background: $__red;
	border-radius: 50px;
	position: $__absolute;
	top: 10px;
	right: 10px;
	color: $__white;
	font-size: $__font__size__12;
}
.dashboard-menu {
	&.coaurt-menu-dash {
		ul {
			li {
				position: relative;
				span {
					&.court-notify {
						@extend %flex-align-center;
					}
				}
			}
		}
	}
}
.table-head-name {
	&.table-name-user {
		a {
			margin-bottom: 0;
		}
	}
}
.table-date-time {
	h4 {
		font-size: $__font__size__14;
		color: $__secondarycolor;
		font-weight: 400;
		margin-bottom: 0;
		span {
			@extend %display-flex;
			padding-top: 5px;
		}
	}
}
.paid-edit {
	span {
		background: $__paid__color;
		padding: 5px;
		color: $__primarycolor;
		border-radius: 2px;
		i {
			margin-right: 5px;
		}
	}
}
.table-accept-btn {
	.btn {
		color: $__white;
		padding: 7px 15px;
		i {
			margin-right: 5px;
		}
	}
	.accept-btn {
		background: $__primarycolor;
		margin-right: 5px;
		&:hover {
			background: $__secondarycolor;
		}
	}
	.cancel-table-btn {
		background: $__red;
		&:hover {
			background: $__secondarycolor;
		}
	}
	.initiate-table-btn{
		color: $__white;
		background: $__red;
		&:hover {
			background: $__secondarycolor;
		}
	}
	&.table-btn-split{
		justify-content: space-between;
		display: flex;
		width: 100%;
	}
}
.pay-dark {
	font-weight: 500;
}
.table-rating {
	.rating-point {
		margin-bottom: 0;
	}
}
.request-modal {
	&.modal {
		.modal-dialog {
			max-width: 1140px;
		}
	}
	.modal-header {
		padding: 24px;
		.badge {
			@include respond-below(custom767) {
				display: inline-block;
				margin-left: 0 !important;
			}
		}
	}
	.modal-body {
		padding: 24px;
	}
	.modal-footer {
		padding: 20px;
	}
}
.modal-header-title {
	h4 {
		font-size: $__font__size__24;
		@include respond-below(custom767) {
			font-size: $__font__size__20;
		}
	}
}
.dashboard-card {
	&.court-information {
		background: $__dull__whitesmoke;
		padding: 15px;
		box-shadow: none;
		.card-header {
			margin-bottom: 15px;
			padding-bottom: 0;
			border-bottom: 0;
			h4 {
				font-size: $__font__size__18;
			}
		}
		.appointment-info {
			li {
				margin-bottom: 0;
			}
			&.appoin-border {
				border: 1px solid $__lightgray;
				padding: 15px;
				&.double-row {
					margin-bottom: 15px;
				}
				li {
					p {
						font-size: $__font__size__14;
					}
				}
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.table-search-top {
    @extend %display-flex-center;
    @extend %justify-content-end;
	@include respond-below(custom767) {
		flex-direction: $__column;
		margin-top: 15px;
		.request-coach-list{
			margin-top: 15px;
		}
	}
	&.invoice-search-top{
		#tablefilter{
			.form-control {
				padding: 10px;
				height: 50px;
				&::-webkit-search-cancel-button {
					display: none;
				}
			}
		}
		.invoice-sort {
			margin-right: 15px;
			@include respond-below(custom767) {
				margin-right: 0px;
			}
			.sortbyset {
				@include respond-below(custom767) {
					margin-bottom: 0px;
					margin-top: 15px;
				}
			}
		}
	}
	#tablefilter{
		max-width: 238px;
		margin-right: 15px;
		.form-control{
			min-width: $__auto;
		}
	}
}
.court-tab-content {
	.card-body{
		.coache-head-blk{
			.table-search-top {
				.request-coach-list {
					ul {
						li {
							a {
								padding: 7px 13px;
							}
						}
					}
				}
			}
		}
	}
}
.modal-header {
	.close {
		i {
			color: $__red;
		}
	}
}
.color-green {
	color: $__primarycolor !important;
}
.reason-court {
	label {
		margin-bottom: 10px;
	}
}
.card-chart-blk {
	margin-bottom: 40px;
	&.card {
		border: 0;
	}
	.card-body {
		padding: 0;
	}
}
.sortby-section {
	&.invoice-sort {
		.sorting-info {
			padding: 0;
			margin: 0;
			background: transparent;
			border: 0;
			box-shadow: none;
			.sortby-filter-group {
				@include respond-below(custom991) {
					-webkit-box-pack: end;
					-ms-flex-pack: end;
					justify-content: flex-end;
					margin-top: 0;
				}
			}
		}
	}
}
.badge{
	padding: 5px;
	border-radius: 2px;
	font-size: 14px;
	font-weight: 400;
	display: inline-flex;
	@include respond-below(custom991) {
		display: inline;                          
	}
	&.bg-success{
		background-color: $__paid__color !important;
		color: $__primarycolor;
	}
	&.bg-info{
		background-color:$__dark__viloet__bg!important;
		color: $__behance_viloet;
	}
	&.bg-danger{
		background-color:$__black__red !important;
		color: $__red;
	}
	&.bg-warning{
		background-color: rgba(12, 174, 244, 0.2) !important;
		color:$__star__blue;
	}
}
.text-primary{
	color: $__primarycolor !important;
	font-weight: 500;
}
.request-coach-list{
	&.select-filter{
		.sortbyset{
			margin: 0;
		}
		.select2-container{
			min-width: 153px;
		}
		.select2-container--default {
			&.select2-container--open {
				.select2-selection--single .select2-selection__arrow b {
					transform: rotate(-135deg);
				}
			}
			.select2-selection--single {
				height: 50px;
				.select2-selection__rendered {
				    height: 50px;
					line-height: 45px;
					font-size: 16px;
					border: $__none;
					background:$__white;
					border-radius: 5px;
					border: 3px solid $__lightgray;
				}
				.select2-selection__arrow{
						height: 50px;
					b {
						border-top: 0;
						border-left: 0;
						border-bottom: 2px solid $__secondarycolor;
						border-right: 2px solid $__secondarycolor;
						width: 9px;
						height: 9px;
						margin-top: -7px;
						display: $__inline__block;
						transform-origin: 66% 66%;
						transform: rotate(45deg);
						transition: all 0.15s ease-in-out;
					}
				}
			}
		}
	}
}
.card-header-btns{
	.nav-tabs{
		border: 0;
		.nav-link{
			border: 0;
			font-size: 14px;
			padding: 8px 10px;
			border-radius: 10px;
			color: #192335;
			&.active{
				background-color: $__primarycolor;
				color: $__white;
				&:hover{
					background-color: $__primarycolor;
					color: $__white;
				}
			}
			&:hover{
				background-color: $__secondarycolor;
				color: $__white;
			}
			& + .nav-link{
				margin-left: 10px;
			}
		}
	}
}
.user-review-details {
    border: 1px solid #EAEDF0;
    padding: 15px;
    border-radius: 10px;
	display: flex;
	flex-wrap: wrap;
	.user-review-img{
		margin-right:10px;
		@include respond-below(custom575) {
			margin-bottom: 15px;
		}
		img{
			width: 50px;
			height:50px;
			border-radius: 50px;
		}
	}
	.user-review-content{
		.rating-point{
			margin-bottom: 5px;
			span{
				color: #6B7385;
				font-size: 14px;
				margin-bottom: 0;
			}
		}
		span{
			color: #23B33A;
			margin-bottom: 14px;
			display: inline-block;
		}
		h6{
			color: #192335;
			font-size:16px;
			font-weight: 500;
			margin-bottom: 8px;
		}
		p{
			font-size: 16px;
			color: #6B7385;
			margin-bottom: 10px;
		}
		h5{
			font-size: 14px;
			color: #6B7385;
			margin: 0;
			font-weight: 400;
		}

	}
}
.track-statistics {
	margin-bottom: 15px;
	.statistic-head {
		@extend %display-flex-center;
		@extend %justify-content-between;
		h5 {
			font-size: $__font__size__14;
			margin-bottom: 0;
		}
		p {
			margin-bottom: 0;
			font-size: $__font__size__14;
		}
	}
	.progress {
		height: 10px;
	}
	.progress-bar {
		background: $__primarylight;
	}
}
.progress-titles {
	h5 {
		font-size: $__font__size__14;
		margin-bottom: 15px;
	}
	ul {
		@extend %display-flex-center;
		@include respond-below(custom767) {
			display: $__block;
		}
		li {
			background: $__dull__whitesmoke;
			padding: 5px 10px;
			margin-bottom: 15px;
			border-radius: 3px;
			font-size: $__font__size__14;
			color: $__primarycolor;
			i {
				margin-right: 5px;
			}
			&+li {
				margin-left: 15px;
				@include respond-below(custom767) {
					margin-left: 0px;
				}
			}
		}
		&.need-complete {
			li {
				color: $__red;
				background: rgba(245, 3, 3, 0.05);
				@extend %display-flex-center;
			}
		}
	}
}
.going-appoinment {
	@extend %display-flex-center;
	@extend %justify-content-between;
	@include respond-below(custom767) {
		display: block;
	}
	.on-going-blk {
		@include respond-below(custom767) {
			margin-bottom: 10px;
		}
	}
	.complete-btn {
		i {
			margin-right: 5px;
		}
	}
}
.academy-new-icon {
	i {
		font-size: 22px;
	}
}
.view-all-fav {
	a {
		color: $__secondarycolor;
		font-size: $__font__size__14;
		border-bottom: 2px solid $__secondarycolor;
		&:hover {
			color: $__primarycolor;
			border-bottom: 2px solid $__primarycolor;
		}
	}
}
.availability-group {
	margin: 20px 0px 0px;
	@include respond-below(custom767) {
		margin-bottom: 0;
	}
}
.availability-box {
	background: $__white;
	box-shadow: $__row__box__shadow;
	border-radius: 10px;
	padding: 15px;
	text-align: center;
	margin-bottom: 24px;
	.available-date {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid $__bright__gray;
		h6 {
			margin-bottom: 0;
			font-weight: 500;
		}
		span {
			font-size: $__font__size__14;
		}
	}
	.available-time {
		span {
			font-size: $__font__size__14;
			color: $__secondarycolor;
		}
		h5 {
			font-size: $__font__size__16;
			color: $__primarycolor;
			font-weight: 500;
			margin-bottom: 0;
			@include respond-below(custom767) {
				font-size: $__font__size__14;
			}
		}
	}
}
.notification-coach {
	.notify-box {
		background: $__dull__whitesmoke;
		@extend %display-flex-center;
		border-radius: 10px;
		padding: 10px;
		margin: 24px 0px;
	}
	.avatar {
		width: 50px;
		height: 50px;
		margin-right: 10px;
		img {
			width: 100%;
			border-radius: 10px;
		}
	}
	.notify-detail {
		h5 {
			font-size: $__font__size__16;
			font-weight: 500;
			margin-bottom: 0;
			span {
				font-size: $__font__size__14;
				font-weight: 400;
			}
		}
		.hour-notify {
			font-size: $__font__size__14;
			font-weight: 400;
			color: $__darkgray;
			margin-bottom: 5px;
			@extend %display-flex;
		}
		.notify-btn-group {
			.btn {
				padding: 2px 15px;
				min-width: 100px;
				&:first-child {
					margin-right: 6px;
					@include respond-below(custom767) {
						margin-bottom: 10px;
						margin-right: 0px;
					}
				}
				&.view-detail-btn {
					min-width: 139px;
				}
			}
		}
	}
}
.statistic-simply {
	width: 100%;
}
.recent-chat-img {
	position: relative;
}
.my-availability {
	@extend %display-flex-center;
	@include respond-below(custom767) {
		display: block;
	}
	.edit-availability {
		@include respond-below(custom767) {
			text-align: right;
		}
		.btn {
			margin-left: 15px;
			font-size: $__font__size__14;
			i {
				margin-right: 5px;
			}
		}
	}
}
.request-point {
	width: 25px;
    height: 25px;
    background: $__red;
    border-radius: 50px;
	margin-left: 10px;
    color: #FFFFFF;
    font-size: $__font__size__12;
	@extend %flex-align-center;
}
.request-book {
	h4 {
		@extend %flex-align-center;
	}
}
