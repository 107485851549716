.eventModal.modal {
    .modal-content {
        padding: 20px;
        .modal-title.h4 {
            font-family: "Cabin";
            font-size: 16px;
            font-weight: 700;
            line-height: 19.44px;
            color: #1A1A1A;
        }
        span.badge.bg-warning {
            background-color: #FFE500 !important;
            display: inline-block;
            width: fit-content;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            color: #1A1A1A;
            margin-bottom: 10px;
        }
        .card-title.h5 {
            font-family: "Cabin";
            font-size: 20px;
            font-weight: 600;
            line-height: 24.3px;
            color: #1A1A1A;
        }
        .titleDescription.h6 {
            color: #3C3C3C;
            font-weight: 500;
        }
        .Description {
            font-family: "Cabin";
            font-size: 14px;
            font-weight: 400;
            line-height: 17.01px;
            color: #3C3C3C;
        }
        span.h6 {
            color: #3C3C3C;
        }
        ul.sportWrap {
            display: flex;
            gap: 10px;
            overflow: auto;
            margin-bottom: 32px;
            li {
                flex: 0 0 auto;
                width: 16.666%;
                small.text-muted {
                    font-family: "Cabin";
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.44px;
                    text-align: left;
                    color: #1A1A1A !important;
                }
                p {
                    font-family: 'Cabin';
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    text-align: center;
                    color: #1A1A1A;
                    margin: 4px 0 0 0;
                }
            }
        }
    }
}


@media (min-width: 600px) {
    .eventModal.modal {
        .modal-dialog {
            max-width: 536px;
        }
    }
}