/*-----------------
	10. Select
-----------------------*/

.select2-results__option {
	@include margin-padding(null, $__value__10);
	font-size: $__font__size__14;
}
.select2-container {
	.select2-selection--single {
		border: $__none;
		height: $__value__60;
		outline: $__none;
		outline-color: $__transparent;
		.select2-selection__rendered {
			padding-right: 30px;
			padding-left: 15px;
		}
	}
}
.select2-dropdown {
    z-index: 1055;
}
.select2-container--default {
	&.select2-container--open {
		&.select2-container--below {
			.select2-selection--single {
				.select2-selection__rendered {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
		&.select2-container--above {
			.select2-selection--single {
				.select2-selection__rendered {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}
			}
		}
	}
	.select2-selection--single {
		&:focus {
			outline: 0;
			box-shadow: none;
		}
		.select2-selection__arrow {
			height: $__value__60;
			right: 2px;
			&:focus {
				outline: 0;
				box-shadow: none;
			}
			b {
				border-color: $__lightgray $__transparent $__transparent;
				border-style: solid;
				border-width: 6px 6px 0;
				height: 0;
				margin-left: -10px;
				margin-top: -2px;
				width: 0;
				@include position($__absolute, 50%, null, null, 50%);
			}
		}
		.select2-selection__rendered {
			height: $__value__60;
			line-height: 55px;
			font-size: $__font__size__16;
			border: $__none;
			background: $__light__whitesmoke; 
			border-radius: $__value__10;
		}
	}
	&.select2-container--open {
		.select2-selection--single {
			.select2-selection__arrow {
				b {
					border-color: $__transparent $__transparent $__lightgray;
					border-width: 0 6px 6px;
				}
			}
		}
	}
	.select2-results__option--highlighted[aria-selected] {
		background-color: $__secondarycolor;
	}
	.select2-selection--multiple {
		border: 1px solid $__lightgray;
		min-height: 46px;
	}
}
.select2-dropdown {
	border-color: $__lightgray;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #676767;
    font-size: 14px;
    font-weight: normal;
    line-height: 38px;
}
.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 30px;
    padding-left: 12px;
    padding-top: 10px;
}