.teamsPage {
    .singleTeam {
        max-width: 850px;
        margin: auto;
        padding: 150px 16px;
        .teamImg {
            img {
                width: 250px;
                height: 250px;
                border-radius: 100%;
                border: 1px solid #707070;
            }
        }
        .teamContent {
            h4 {
                color: #202020;
                font-size: 24px;
            }
            p {
                color: #202020;
            }
        }
    }
    .upperTab {
        background: #000;

        .nav.nav-pills {
            .w-33.nav-item {
                width: 33%;
                .nav-link.active {
                    background-color: #fff;
                    color: #000;
                }
                .nav-link {
                    padding: 20px;
                    border-radius: 0px;
                    color: #fff;
                    text-align: center;
                }
            }
        }
    }
    table.table {
        td, th {
            border: 1px solid #EAEDF0;
            white-space: nowrap;
        }
        .matchBetween {
            label {
                font-weight: 700;
                color: #000;
                font-size: 16px;
            }
            img.w-50px {
                width: 50px;
            }
        }
        .fit-content{
            width: fit-content;
        }
    }
}

@media only screen and (max-width: 768px) {
    .teamsPage {
        .singleTeam {
            padding: 30px 16px;
        }
    }
    .firstTabContent {
        table.table {
            width: 850px;
            margin: auto;
            min-width: 850px;
        }
    }
}
@media only screen and (max-width: 575px) {
    .teamsPage {
        .singleTeam {
            .teamContent {
                display: flex;
                flex-direction: column;
                align-items: center;
                h1.pb-4 {
                    padding-bottom: 10px !important;
                }
                .mb-5 {
                    margin-bottom: 10px !important;
                }
                h4, p {
                    text-align: center;
                }
            }
            .teamImg {
                display: flex;
                justify-content: center;
                img {
                    width: 200px;
                    height: 200px;
                }
            }
        }
        .upperTab {
            .nav.nav-pills {
                .w-33.nav-item {
                    width: 100%;
                }
            }
        }
    }
}