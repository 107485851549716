@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');
@font-face {
    font-family: "FuturaPTBook";
    src: url('../fonts/FuturaPTBook.eot');
    src: url('../fonts/FuturaPTBook.eot') format("embedded-opentype"),
    url('../fonts/FuturaPTBook.otf') format("otf"), 
    url('../fonts/FuturaPTBook.woff') format("woff"), 
    url('../fonts/FuturaPTBook.ttf') format("truetype"), 
    url('../fonts/FuturaPTBook.svg') format("svg");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "FuturaPTHeavy";
    src: url('../fonts/FuturaPTHeavy.eot');
    src: url('../fonts/FuturaPTHeavy.eot') format("embedded-opentype"),
    url('../fonts/FuturaPTHeavy.otf') format("otf"), 
    url('../fonts/FuturaPTHeavy.woff') format("woff"), 
    url('../fonts/FuturaPTHeavy.ttf') format("truetype"), 
    url('../fonts/FuturaPTHeavy.svg') format("svg");
    font-weight: normal;
    font-style: normal;
  }
// @import url(variables.scss);
body {
    font-family: 'Cabin';
}
::-webkit-scrollbar-track
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
    height: 10px;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
a, button{
    transition: 0.5s all ease-in-out;
    &:hover {
        opacity: 0.7;
    }
}
h1 {
    font-family: "Cabin";
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    color: rgba(26, 26, 26, 1);
}
::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #f0f0f0;
}
::-webkit-scrollbar {
	width: 10px;
	background-color: #f0f0f0;
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #ccc;
}
input.commonInput.form-control, select.commonInput.form-control {
    height: 65px;
    padding: 15px 24px;
    border-radius: 8px;
    border: 1px solid rgb(233 233 233);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    font-family: 'Cabin';
    &:focus {
        border: 1px solid rgba(26, 128, 199, 0.6);
        box-shadow: none;
    }
}
input.commonInput.form-control[disabled] {
    background: #fff;
    opacity: 55%;
    border: 1px solid rgb(233 233 233) !important;
}
select.commonInput.form-control {
    appearance: auto;
    -webkit-appearance: auto;
    -moz-appearance: auto;
}
textarea.form-control {
    border: 1px solid rgb(233 233 233) !important;
    background: #FFFFFF;
    height: 125px;
    resize: none;
    &:focus {
        border: 1px solid rgba(26, 128, 199, 0.6);
        box-shadow: none;
    }
}
p.errorMessage {
    color: #dc3545!important;
    font-size: 12px !important;
    padding-top: 2px;
}
button.toggleMenu {
    background: transparent;
    border: none;
}
ul.main-nav li a svg, ul.main-nav li a svg path {
    fill: #fff;
    color: #fff;
}
.authendication-pages {
    .content {
        form {
            .user-login-check {
                input[type=checkbox] {
                    width: 40px !important;
                }
            }
            .form-check.form-switch {
                .form-check-input {
                    background: #ECECEC !important;
                    &:after {
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        content: "";
                        width: 16px;
                        height: 16px;
                        border-radius: 10px;
                        border: none;
                        transition: all 0.5s ease-in-out 0s;
                        background: #fff !important;
                    }
                    &:checked {
                        border-color: #ECECEC !important;
                        &::after {
                            background: #192335 !important;
                        }
                    }
                }
                .form-check-input:not(:checked) {
                    border-color: #ECECEC !important;
                }
            }
        }
    }
}
.form-check {
    label.form-check-label {
        margin-left: 8px;
        font-family: "Cabin";
        color: rgba(26, 26, 26, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
    }
    input[type="checkbox"] {
        width: 18px !important;
        height: 18px;
        border: 1px solid rgba(26, 128, 199, 1);
    }
    input[type="checkbox"]#custom-switch {
        background-color: rgba(236, 236, 236, 1);
        width: 40px !important;
        height: 20px;
        &:focus {
            box-shadow: none;
        }
        &:checked {
            background-color: rgba(26, 128, 199, 1);
            border-color: rgba(26, 128, 199, 1);
        }
    }
}
button.primaryButton, a.primaryButton {
    background: rgba(26, 128, 199, 1);
    border: 1px solid rgba(26, 128, 199, 1);
    height: 51px;
    border-radius: 10px;
    font-family: 'Cabin';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    &:hover, &:active, &:focus {
        background: rgb(0 104 177) !important;
        border-color: rgb(0 104 177);
        box-shadow: none;
    }
}
button.cancelBtn {
    color: rgba(26, 26, 26, 1);
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    &:hover, &:active, &:focus {
        background: transparent !important;
        border: none !important;
        color: rgba(26, 26, 26, 1) !important;
    }
}
button.btn.btn-secondary.AddIcon {
    svg.bi.bi-plus {
        width: 22px;
        height: 22px;
    }
}
.radius8px {
    border-radius: 8px;
}
.mb-32px {
    margin-bottom: 32px;
}
.commonGrid {
    border: 1px solid #F6F6F6;
    border-radius: 8px;
    padding: 16px 20px;
    margin-bottom: 24px;
    .friendBlock {
        box-shadow: 0 0 4px #0c0c0d0d;
        border-radius: 8px;
        border: 1px solid #f6f6f6;
        padding: 0 16px 16px;
        border-top: 16px solid #1a80c7;
        text-align: center;
        .friendImage {
            img {
                width: 115px;
                height: 115px;
                border: 2px solid #fff;
                margin-top: -10px;
                border-radius: 100%;
                object-fit: cover;
                -webkit-object-fit: cover;
            }
        }
        h5 {
            text-overflow: ellipsis;
            font-family: "Cabin";
            font-size: 16px;
            font-weight: 400;
            line-height: 19.44px;
            color: #1A1A1A;
            margin-top: 24px;
            margin-bottom: 12px;
        }
    label {
        background: #F3F3F3;
        padding: 8px;
        border: 1px solid #F6F6F6;
        border-radius: 4px;
        margin: 6px 0;
        font-family: 'Cabin';
        font-size: 14px;
        font-weight: 400;
        line-height: 17.01px;
        text-align: left;
        strong {
            font-weight: 500;
            font-family: 'Cabin';
            padding-left: 3px;
        }
    }
    button.friendBtn {
        border: 1px solid #1a80c7;
        background: transparent;
        font-family: "Cabin";
        margin-top: 18px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 15px;
        color: #1a80c7;
        gap: 8px;
        &:hover {
            border: 1px solid #1a80c7;
            color: #fff;
            background: #1a80c7;
            svg, svg path {
                fill: #fff;
                color: #fff;
            }
        }
    }
    button.declineBtn {
        border: 1px solid #1a80c7;
        background: transparent;
        font-family: "Cabin";
        margin-top: 18px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 15px;
        color: #1a80c7;
        gap: 8px;
        &:hover {
            border: 1px solid #1a80c7;
            color: #fff;
            background: #1a80c7;
            svg, svg path {
                fill: #fff;
                color: #fff;
            }
        }
    }
    }
    .teamBlock {
        box-shadow: 0 0 4px rgba(12, 12, 13, 0.0509803922);
        padding: 8px;
        border-radius: 10px;
        .teamImage {
            img {
                border-radius: 4px;
            }
        }
        h5 {
            font-family: "Cabin";
            font-size: 16px;
            font-weight: 400;
            line-height: 19.44px;
            color: #1A1A1A;
            margin: 12px 0 24px 0;
            text-transform: capitalize;
        }
        .teamViewBtn {
            background: #1a80c7;
            border: 1px solid #1a80c7;
            border-radius: 10px;
            padding: 15px;
            font-family: "Cabin";
            font-size: 16px;
            font-weight: 400;
            line-height: 19.2px;
        }
    }
}
.slotCard {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: #fff;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}
  
//   .slotCard:hover {
//     transform: translateY(-5px);
//   }
  
  .slotImage {
    position: relative;
  }
  
  .slotImage img {
    width: 100%;
    height: auto;
  }
  
  .newBadge {
    position: absolute;
    top: 8px;
    right: 8px;
    background: #d22b2b;
    color: #fff;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 8px;
  }
  
  .slotDetails {
    padding: 16px;
  }
  
  .slotDetails h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.44px;
    text-align: left;
    text-transform: capitalize;
}
  
.slotDescription {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.01px;
    text-align: left;
    margin-bottom: 16px;
}
.slotFooter span.status {
    background: #1AC740;
    color: #fff;
    font-family: Cabin;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.01px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.slotFooter span.booked {
    background: #1a80c7;
}
.inr-slots-ab span {
    background: rgba(199, 26, 29, 0.1019607843);
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid rgba(199, 26, 29, 0.1098039216);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #C71A1D;
}
.inr-slots-ab span i {
    padding: 2px;
    background: #C71A1D;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-block;
    line-height: 26px;
    text-align: center;
    color: #fff;
    font-style: normal;
    font-weight: 500;
}
  .slotDescription a {
    color: #1a80c7;
    text-decoration: underline;
  }
  
  .slotFeatures {
    list-style: none;
    padding: 0;
    margin: 0 0 16px 0;
  }
  
  .slotFeatures li {
    font-size: 14px;
    color: #333;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }
  
  .slotFeatures li:before {
    content: '✓';
    margin-right: 8px;
    color: #1a80c7;
    font-weight: bold;
  }
  
  .slotFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
}
  
  .status {
    font-size: 14px;
    font-weight: bold;
    padding: 2px 8px;
    border-radius: 4px;
    text-transform: capitalize;
  }
  
  .status.available {
    background: #e0f7e9;
    color: #4caf50;
  }
  
  .status.booked {
    background: #fff3e0;
    color: #ff9800;
  }
  
  span.price {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.16px;
    text-align: left;
}
  
  .bookBtn {
    width: 100%;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: #1a80c7;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .bookBtn.disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .bookBtn:not(.disabled):hover {
    background: #155d94;
  }
  

button.eyeBtn {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    &::before {
        content: '';
        position: absolute;
        right: 35px;
        top: 4px;
        width: 1px;
        height: 20px;
        background: rgba(90, 90, 90, 1);
    }
}


.user-login-check input[type=checkbox] {
    width: 34px !important;
}

.text-underline{
    text-decoration: underline;
}

.proifleSubTabContent  {
    .bgFormColor {
        background: #FAFAFA;
        padding: 24px 20px;
        border: 1px solid #F6F6F6;
        border-radius: 8px;
    }
}
.accordionWrap {
    border: 1px solid #F6F6F6;
    background: #FFFFFF;
    padding: 24px 20px;
    border-radius: 8px;
    .accordion-item {
        margin: 8px 0;
        border-radius: 8px;
        border: 1px solid #F6F6F6;
    }
}
.blueButton {
    border: 1px solid #1a80c7;
    background: #1a80c7;
    font-family: "Cabin";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 15px;
    color: #fff;
    min-width: 120px;
    &:hover {
        border: 1px solid #1a80c7;
        background: #1a80c7;
        color: #fff;
        opacity: 0.7;
    }
}
button.defaultButton.btn.btn-default {
    font-family: "Cabin";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: #1a80c7;
    &:hover {
        opacity: 0.7;
    }
}
/* Header design changes css start */
.loginProfileWrapper {
    button.header-profile-button.show {
        background: transparent;
    }
    .dropdownWrap {
        .profileMember {
            padding: 24px 30px;
            &:hover {
                .profileImg {
                    img {
                        border: 2px solid rgba(26, 128, 199, 1);
                    }
                }
            }
            .profileImg {
                img {
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    border-radius: 100px;
                    border: 2px solid transparent;
                }
            }
            h3 {
                font-family: "Cabin";
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                color: rgba(26, 128, 199, 1);
            }
            
            span.badge {
                background: rgba(234, 241, 255, 1);
                border: 1px solid rgba(204, 221, 249, 1);
                color: rgba(26, 128, 199, 1);
                font-family: "Cabin";
                font-size: 12px;
                font-weight: 400;
                line-height: 14.58px;
                letter-spacing: 0.01em;
                border-radius: 6px;
            }
            a.mail {
                font-family: "Cabin";
                font-size: 16px;
                font-weight: 400;
                line-height: 19.44px;
                text-align: left;
                color: rgba(60, 60, 60, 1);
            }
        }
        a.dropdown-item {
            border: none !important;
        }
        .linksWrap {
            padding: 8px 12px 0px 12px;
            a.dropdown-item {
                padding: 16px !important;
                border-radius: 8px !important;
                margin: 8px 0;
                border-left: 1px solid transparent !important;
                &:hover {
                    background-color: #edf5fb;
                    border-left: 1px solid rgba(26, 128, 199, 1) !important;
                    color: #1a80c7 !important;
                    opacity: 1 !important;
                    svg {
                        fill: rgb(26, 128, 199);
                        color: rgb(26, 128, 199);
                        path {
                            fill: rgb(26, 128, 199);
                            color: rgb(26, 128, 199);
                        }
                    }
                }
                svg {
                    margin-right: 8px;
                }
            }
        }
        .logoutBtnWrap {
            padding: 0 12px;
            a.dropdown-item {
                padding: 16px !important;
                border-radius: 8px !important;
                margin: 5px 0;
                border-left: 1px solid transparent !important;
                &:hover {
                    background-color: #edf5fb;
                    border-left: 1px solid rgba(26, 128, 199, 1) !important;
                    color: #1a80c7 !important;
                    opacity: 1 !important;
                    svg {
                        fill: rgb(26, 128, 199);
                        color: rgb(26, 128, 199);
                        path {
                            fill: rgb(26, 128, 199);
                            color: rgb(26, 128, 199);
                        }
                    }
                }
                svg {
                    margin-right: 8px;
                }
            }
        }
    }    
}
/* Header design changes css end */
.editDropdown {
    button, button.show {
        color: #3c3c3c;
        padding-top: 0px;
        padding-bottom: 0px;
        background-color: transparent;
        &:hover {
            background: transparent;
            color: #3c3c3c;
        }
        &::after {
            display: none;
        }
    }
}
.breadcrumbWrap {
    span.text-primary {
        color: #1a80c7 !important;
    }
}
/* taskmodal start scss */
.taskModal.modal {
    .modal-title.h4 {
        font-family: "Cabin";
        font-size: 16px;
        font-weight: 700;
        line-height: 19.44px;
        color: #1A1A1A;
    }
}
/* taskmodal end scss */
/* event confirmed modal scss start */
.eventConfirmModal  {
    h2 {
        font-family: "Cabin";
        font-size: 24px;
        font-weight: 500;
        line-height: 28.8px;
        text-align: center;
        color: #1A1A1A;
    }
    h3 {
        font-family: "Cabin";
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: #1A1A1A;
    }
    p {
        font-family: "Cabin";
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        color: #5A5A5A;
    }
}
.editDropdown {
    .dropdown-menu {
        justify-content: center;
        min-width: fit-content;
        padding: 5px 15px;
        a.dropdown-item {
            width: fit-content;
        }
    }
}
/* event confirmed modal scss end */
/* Event reject modal scss start */
.eventRejectModal {
    h2 {
        font-family: "Cabin";
        font-size: 24px;
        font-weight: 500;
        line-height: 28.8px;
        text-align: center;
        color: #1A1A1A;
    }
    h3 {
        font-family: "Cabin";
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: #1A1A1A;
    }
    p {
        font-family: "Cabin";
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        color: #5A5A5A;
    }
}
/* Event reject modal scss end */
/* trainer rightsidebar scss start */
.blockcreate {
    background: #f1f7fc;
    padding: 16px 20px;
    border-radius: 8px;
    border: 1px solid rgba(26, 128, 199, 0.1);
    label {
        font-family: "Cabin";
        font-size: 16px;
        font-weight: 400;
        line-height: 19.44px;
        color: rgba(26, 26, 26, 1);
        strong {
            font-family: "Cabin";
            font-size: 24px;
            font-weight: 600;
            line-height: 29.16px;
        }
    }
}


/* trainer rightsidebar scss end */
/* rightsidebar calendar scss start */
.calendarWrapper {
    background: rgba(250, 250, 250, 1);
    padding: 15px;
    border: 1px solid rgba(246, 246, 246, 1);
    border-radius: 8px;
    margin-bottom: 15px;
    h2.fc-toolbar-title {
        font-family: "Cabin";
        font-size: 20px;
        font-weight: 600;
        line-height: 24.3px;
        text-align: left;
        color: rgba(26, 26, 26, 1);
    }
    button.fc-today-button.fc-button.fc-button-primary {
        display: none;
    }
    .fc-button-group {
        gap: 10px;
        button.fc-button-primary {
            background: transparent;
            border-radius: 100px !important;
            height: 38px;
            width: 38px;
            border: 1px solid #000 !important;
            padding: 5px;
            span.fc-icon.fc-icon-chevron-left {
                transform: rotate(-90deg);
            }
            span.fc-icon.fc-icon-chevron-right {
                transform: rotate(-90deg);
            }
            span.fc-icon {
                &::before {
                    color: #000;
                }
            }
        }
    }
    .fc {
        .fc-col-header-cell-cushion {
            font-family: "Cabin";
            font-size: 14px;
            font-weight: 400;
            line-height: 17.01px;
            color: rgba(26, 26, 26, 1);
        }            
    }
    .fc-theme-standard {
        .fc-scrollgrid {
            border: none;
        }
         td, th {
            border: none;
         }
    }
     ul {
        padding-top: 15px;
        li {
            padding: 5px 10px 5px 20px;
            border-radius: 4px;
            font-family: "Cabin";
            font-size: 14px;
            font-weight: 400;
            line-height: 17.01px;
            color: rgba(26, 26, 26, 1);
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: 10px;
                width: 6px;
                height: 6px;
                border-radius: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:first-child {
                background: #e5f3ff;
                &::before {
                    background-color: #1a80c7;
                }
            }
            &:nth-child(2) {
                background: #fff4e8;
                &::before {
                    background-color: #ff9f2d;
                }
            }
            &:last-child {
                background: #e5f8eb;
                &::before {
                    background-color: #00ba34;
                }
            }
        }
        
    }
}
/* rightsidebar calendar scss end */

.alerttable  {
    table.table {
        thead {
            background: #f1f7fc;
        }
    }   
}
.eventstable {
    table.table {
        thead {
            background: #fafafa;
        }
    }
}
.scrollHeight {
    max-height: 350px;
    table.table {
        thead {
            position: sticky;
            top: 0;
            z-index: 1;
        }
    }
}


/* media Query start */

@media (min-width: 2500px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 75%;
    }
    .profileBlock {
        margin-top: -170px;
    }
    nav.headerHome.navbar:after {
        width: 55%;
    }
}
@media (min-width: 3000px) {
    nav.headerHome.navbar:after {
        width: 45%;
    }
}
@media (min-width: 3700px) {
    nav.headerHome.navbar:after {
        width: 40%;
    }
}
@media (min-width: 4500px) {
    nav.headerHome.navbar:after {
        width: 36%;
    }
}
@media (min-width: 5500px) {
    nav.headerHome.navbar:after {
        width: 32%;
    }
}
@media (min-width: 6500px) {
    nav.headerHome.navbar:after {
        width: 30%;
    }
}
@media (min-width: 7500px) {
    nav.headerHome.navbar:after {
        width: 27%;
    } 
}
@media (min-width: 8500px) {
    nav.headerHome.navbar:after {
        width: 25%;
    }
}
@media (min-width: 767px) {
    .taskModal.modal {
        .modal-dialog {
            max-width: 675px
        }
    }
    .eventConfirmModal {
        .modal-dialog {
            max-width: 536px;
        }
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .calendarWrapper {
        padding: 8px;
        h2.fc-toolbar-title {
            font-size: 14px;
            line-height: 20px;
        }
        .fc {
            table {
                font-size: 14px;
            }
            .fc-col-header-cell-cushion {
                font-size: 12px;
            }
        }
        ul {
            li {
                padding: 5px 5px 5px 15px;
                font-size: 12px;
                &::before {
                    left: 5px;
                }
            }
        }
        .fc-button-group {
            button.fc-button-primary {
                height: 30px;
                width: 30px;
                span.fc-icon.fc-icon-chevron-left, span.fc-icon.fc-icon-chevron-right {
                    width: 12px;
                    font-size: 18px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .commonGrid {
        padding: 16px 10px;
    }
    .fc-header-toolbar.fc-toolbar {
        flex-direction: column;
    }
    .createEventWrapper.container {
        padding: 0px !important;
    }
    .createEventWrapper {
        margin-top: 0px !important;
        padding: 10px !important;
        .card-body {
            .mb-5 {
                margin-bottom: 1rem !important;
            }
        }
    }
}

.cal-event-card {
    background: rgba(0, 133, 255, 0.1);
    span{
        display: inline-block;
        border-radius: 3px;
        margin-bottom: 2px;
    margin-right: 4px;
        height: 6px;
        width: 6px;
        background: rgba(26, 128, 199, 1);
    }
}

.cal-training-card {
    background:rgba(255, 150, 27, 0.1);
    span{
        display: inline-block;
        border-radius: 3px;
        margin-bottom: 2px;
        margin-right: 4px;
        height: 6px;
        width: 6px;
        background:rgba(255, 150, 27, 1);
    }
}

.cal-practice-card {
    background: rgba(0, 186, 52, 0.1);
    span {
        display: inline-block;
        border-radius: 3px;
        margin-bottom: 2px;
        margin-right: 4px;
          height: 6px;
        width: 6px;
        background: rgba(0, 186, 52, 1);
    }
}

@media(min-width:1440px){
    .available-slots {
        height: 1390px;
        overflow: hidden;
        overflow-y: auto;
    }
}
@media(min-width:1000px){
    .logout-button-header{
        display: none !important;
    }
}

//css for mobile

body.mobile-view{
    .header {
        .navbar-header {
            display: flex !important;
            justify-content: space-between !important;
            #mobile_btn {
                position: inherit;
            }
        }
    }
    .profileBlock {
        .memberInfo {
            ul.memberDetail {
                display: none;
            }
        }
    }
    .fc-toolbar-chunk {
        .fc-button-group {
            margin-left: 0 !important;
        }
    }
    .fc {
        .fc-daygrid-day-top {
            justify-content: center;
        }
        .fc-daygrid-dot-event {
            justify-content: center;
        }
    }
}