.IMPressumPage {
    .textFull {
        padding: 50px 0;
        h1 {
            font-weight: 100;
            text-transform: uppercase;
        }
        p {
            color: #000;
        }
    }
}