.report {
  .present {
    background-color: #ffe5001a;
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    span {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 3px;
      background-color: #ffe500;
      margin-right: 3px;
    }
  }
  .not-present {
    background-color: #0085ff1a;
    display: flex;
    align-items: center;
    margin-inline: 8px;
    font-size: 12px;
    cursor: pointer;
    span {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 3px;
      background-color: #1a80c7;
      margin-right: 3px;
    }
  }
}
